'use client';

import * as RadixAccordion from '@radix-ui/react-accordion';
import clsx from 'clsx';

import { dawnTheme } from '../../styles/theme.css';
import { Box } from '../box';
import { Icon } from '../icon';
import { RichText } from '../rich-text';
import { Text } from '../text';

import * as styles from './accordion.css';

import type { Accordion as AccordionT } from '@solstice/types';

export const Accordion = ({ body, theme = 'midnight', title }: AccordionT.Props) => (
  <RadixAccordion.Root className={clsx(styles.root, theme === 'dawn' && dawnTheme)} collapsible type="single">
    <RadixAccordion.Item className={styles.item} value="item">
      <RadixAccordion.Trigger className={styles.trigger}>
        <RadixAccordion.Header>
          <Box className={styles.triggerContent}>
            <Text className={styles.headingText} style={'body2'} weight={'bold'}>
              {title}
            </Text>
            <Icon className={styles.icon} name="expand_more" />
          </Box>
        </RadixAccordion.Header>
        <RadixAccordion.Content className={styles.content}>
          <RichText data={body} />
        </RadixAccordion.Content>
      </RadixAccordion.Trigger>
    </RadixAccordion.Item>
  </RadixAccordion.Root>
);
