'use client';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Box } from '@ui/components/box';
import { Flex } from '@ui/components/flex';
import { Icon } from '@ui/components/icon';
import Link from '@ui/components/next-link';
import { Text } from '@ui/components/text';

import { arrow, icon, item, itemContainer } from './index.css';

const Item = ({ slug, text }: { slug: string; text: string }) => {
  return (
    <DropdownMenu.Item asChild>
      <Link aria-label={text} className={itemContainer} href={slug}>
        <Flex alignItems="center" className={item} justifyContent="space-between">
          <Text color="none" style="body3">
            {text}
          </Text>
          <Box className={arrow}>
            <Icon className={icon} name="arrow_right_alt" />
          </Box>
        </Flex>
      </Link>
    </DropdownMenu.Item>
  );
};

export default Item;
