export const Smile = () => (
  <svg fill="none" height="64" viewBox="0 0 64 64" width="64" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M32 58C48.0163 58 61 46.3594 61 32C61 17.6406 48.0163 6 32 6C15.9837 6 3 17.6406 3 32C3 46.3594 15.9837 58 32 58ZM15.5 35C17.4259 35 18.9885 36.5555 18.9999 38.4788C21.1349 41.2494 25.6396 45 31.5 45C34.8872 45 38.0026 43.7625 40.5756 42.091C41.9615 41.1907 43.1454 40.1929 44.0828 39.2605C44.0286 39.0156 44 38.7612 44 38.5C44 36.567 45.567 35 47.5 35C49.433 35 51 36.567 51 38.5C51 40.0779 49.9558 41.412 48.5207 41.8488C47.2009 43.3124 45.4251 44.9031 43.2994 46.284C40.164 48.3208 36.1128 50 31.5 50C23.9898 50 18.3728 45.5904 15.4199 41.9991C13.5239 41.9565 12 40.4062 12 38.5C12 36.567 13.567 35 15.5 35Z"
      fill="#FF5647"
      fillRule="evenodd"
    />
  </svg>
);
