import clsx from 'clsx';
import { forwardRef } from 'react';

import { Box } from '../box';

import { text } from './index.css';

import type { TextVariants } from './index.css';
import type { BoxProps } from '../box';
import type { Ref } from 'react';

export type TextProps = BoxProps & TextVariants;

export const Text = forwardRef(
  ({ as = 'div', children, className, color, style, weight, ...props }: TextProps, ref: Ref<HTMLElement>) => {
    return (
      <Box
        as={as}
        className={clsx([text({ color, style, weight }), className])}
        ref={ref}
        style={{ minHeight: '1em' }}
        {...props}
      >
        {children}
      </Box>
    );
  }
);

Text.displayName = 'Text';
