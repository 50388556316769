import { Box } from '@ui/components/box';
import { Container } from '@ui/components/container';
import Image from 'next/image';

import { solsticeArrowContainer, solsticeContainer, solsticeSubContainer } from './solstice.css';

export const Solstice = () => {
  return (
    <Box className={solsticeContainer}>
      <Box className={solsticeSubContainer}>
        <Image alt="" fill src="/squiggle.svg" />
      </Box>
    </Box>
  );
};

export const SolsticeWithArrow = () => {
  return (
    <Container>
      <Box position="relative">
        <Box className={solsticeArrowContainer}>
          <Box className={solsticeSubContainer}>
            <Image alt="" fill src="/squiggle.svg" />
          </Box>
        </Box>
        <svg fill="none" height={136} width={92} xmlns="http://www.w3.org/2000/svg">
          <path
            clipRule="evenodd"
            d="M57.001 11.136c0 2.23-.686 4.3-1.86 6.01l.087.018c-4.822 8.225-5.555 36.045-5.318 48.572.26 5.743 1.098 15.52 1.663 22.119.203 2.364.37 4.32.464 5.535.354 4.61 1.773 8.509 3.19 8.509.201 0 .38.036.541.067.978.193 1.282.254 1.587-8.576.278-8.051 2.512-13.276 5.341-17.037 2.393-5.023 7.517-8.494 13.45-8.494 8.225 0 14.891 6.666 14.891 14.89 0 5.197-2.661 9.772-6.696 12.436l-.837.772-.007.006c-2.207 2.03-4.758 4.374-7.351 7.709-2.856 3.672-3.678 4.475-4.246 5.03-.912.891-1.17 1.143-8.163 11.633-3.79 5.685-6.71 9.075-9.102 11.096a11.667 11.667 0 0 1-8.624 3.793c-4.52 0-8.44-2.562-10.39-6.314-5.395-5.676-11.438-14.241-14.075-18.856-2.439-4.269-7.936-9.884-12.422-14.029-4.83-2.461-8.14-7.482-8.14-13.276 0-8.224 6.667-14.89 14.891-14.89 5.06 0 9.531 2.524 12.222 6.381 5.226 4.68 6.567 15.037 6.567 19.86-.355 2.836-.355 8.366 2.482 7.799 2.947-.589 3.2-5.098 4.218-23.299V78.6c.208-3.696.446-7.956.745-12.863 1.273-20.879-2.501-40.272-5.168-49.663a10.59 10.59 0 0 1-1.212-4.937C35.729 5.262 40.491.5 46.365.5s10.636 4.762 10.636 10.636Z"
            fill="#FF5647"
            fillRule="evenodd"
          />
        </svg>
      </Box>
    </Container>
  );
};
