import { breakpoints } from '@solstice/constants';
import { useMediaQuery } from 'react-responsive';

import type { Breakpoint } from '../../styles/utils/types';

const useIsMinWidth = (breakpoint: Breakpoint) => {
  const IsMinWidth = useMediaQuery({ query: `(min-width: ${breakpoints[breakpoint][0]}px)` });
  return IsMinWidth;
};

const useIsMaxWidth = (breakpoint: Breakpoint) => {
  const IsMaxWidth = useMediaQuery({ query: `(max-width: ${breakpoints[breakpoint][0]}px)` });
  return IsMaxWidth;
};

export { useIsMinWidth, useIsMaxWidth };
