'use client';

import { GoogleMap, useJsApiLoader, StandaloneSearchBox, InfoBox } from '@react-google-maps/api';
import {
  PIPELINE_WORDING,
  PIPELINE_THEME,
  VIC_INITIAL_POSITION,
  TAS_INITIAL_POSITION,
  PROXIMITY_THRESHOLD_FALLBACK,
  POINT_PERCENTAGE_PER_LINE,
  CMS_PIPELINE_FETCH_TIMEOUT_THRESHOLD,
  isVictoria,
  GMAP_API_KEY,
  SCRIPT_LIBRARIES,
} from '@solstice/constants';
import { midnightTheme, dawnTheme, duskTheme } from '@style-system/theme.css';
import Link from '@ui/components/next-link';
import clsx from 'clsx';
import { memo, useState, useEffect, useRef, useMemo } from 'react';

import { useIsMaxWidth } from '../../../hooks/use-width';
import { Box } from '../../box';
import { Button } from '../../button';
import { Container } from '../../container';
import { Flex } from '../../flex';
import { TextInput } from '../../form/text-input';
import { Icon } from '../../icon';
import { LoadingSpinner } from '../../loading-spinner';
import { Text } from '../../text';

import { checkProximity, refineData, fetchGeoDataFromURLWithTimeout } from './helpers';
import {
  container,
  mapWrapper,
  searchBarWrapper,
  searchButton,
  infoBox,
  infoBoxContainer,
  infoBoxFrame,
  infoBoxHeading,
  textBody,
  closeIcon,
  toolTip,
  searchInput,
  searchInputWrapper,
  infoBoxIconColorSuccess,
  infoBoxIconColorError,
  infoBoxIconSize,
  contentLink,
} from './pipeline.css';

import type { Lines, GeoJsonData } from './helpers';
import type { Libraries } from '@react-google-maps/api';
import type { PipelineLocator } from '@solstice/types';

const Component = ({ theme = 'dawn', pipelineURL, proximityThreshold, address, callback }: PipelineLocator.Props) => {
  const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox | null>(null);
  const [markerPosition, setMarkerPosition] = useState<google.maps.LatLng | null>(null);
  const mapRef = useRef<google.maps.Map | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [lines, setLines] = useState<Lines>([]);
  const [isPipeLineLoading, setIsPipeLineLoading] = useState(false);
  const [isNearPipeline, setIsNearPipeline] = useState(false);
  const [isInfoBoxOpen, setIsInfoBoxOpen] = useState(false);
  const [geoData, setGeoData] = useState<GeoJsonData | null>(null);
  const [currentMarker, setCurrentMarker] = useState<google.maps.marker.AdvancedMarkerElement | null>(null);

  const THEME_MAP_ID = PIPELINE_THEME.mapId;
  const THEME_PIPELINE_STROKE_COLOR = PIPELINE_THEME.pipelineStrokeColor;
  const THEME_PIPELINE_MARKER_COLOR = PIPELINE_THEME.markerFillColor;

  const INITIAL_POSITION = isVictoria ? VIC_INITIAL_POSITION : TAS_INITIAL_POSITION;
  const INITIAL_CENTER = INITIAL_POSITION.center;
  const INITIAL_ZOOM = INITIAL_POSITION.zoom;
  const MAP_SEARCH_BOUNDS = INITIAL_POSITION.searchBounds;

  const PROXIMITY_THRESHOLD =
    proximityThreshold && proximityThreshold > 0 ? proximityThreshold : PROXIMITY_THRESHOLD_FALLBACK;

  const isMobile = useIsMaxWidth('tablet');

  const loadScriptOptions = useMemo(
    () => ({
      id: 'google-map-script',
      googleMapsApiKey: GMAP_API_KEY,
      libraries: SCRIPT_LIBRARIES as Libraries,
    }),
    []
  );

  const { isLoaded: isMapLoaded } = useJsApiLoader(loadScriptOptions);

  const onSBLoad = (ref: google.maps.places.SearchBox) => {
    setSearchBox(ref);
  };

  const onMarkerClick = (position: google.maps.LatLng) => {
    if (!position) return;
    setIsInfoBoxOpen(true);
  };

  const onSearchButtonClick = () => {
    const inputVal = inputRef.current?.value;
    if (!inputVal) return;

    const service = new google.maps.places.PlacesService(mapRef.current as google.maps.Map);
    service.textSearch({ query: inputVal }, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        processPlaces(results);
      }
    });
  };

  const onPlacesChanged = () => {
    processPlaces(searchBox?.getPlaces());
  };

  const processPlaces = (places: google.maps.places.PlaceResult[] | undefined | null) => {
    if (places && places.length > 0) {
      const location = places[0].geometry?.location;
      if (!location) return;
      const isNear = checkProximity(location, lines, PROXIMITY_THRESHOLD);

      setIsNearPipeline(isNear);
      callback?.(!!isNear);

      setIsInfoBoxOpen(true);

      setMarkerPosition(location);

      placeAdvancedMarker(location);
    }
  };

  const placeAdvancedMarker = async (position: google.maps.LatLng) => {
    const pinOption = new google.maps.marker.PinElement({
      background: THEME_PIPELINE_MARKER_COLOR,
      borderColor: THEME_PIPELINE_MARKER_COLOR,
      glyphColor: '#fff',
    });

    if (currentMarker) currentMarker.map = null;

    const marker = new google.maps.marker.AdvancedMarkerElement({
      map: mapRef.current,
      position,
      content: pinOption.element,
    });
    marker.addListener('click', () => onMarkerClick(position));
    setCurrentMarker(marker);
  };

  const onInfoBoxCloseClick = () => {
    setIsInfoBoxOpen(false);
  };

  useEffect(() => {
    if (address && inputRef.current) {
      inputRef.current.value = address;
    }

    !isPipeLineLoading && lines && onSearchButtonClick();
  }, [inputRef.current, address, isPipeLineLoading, lines]);

  useEffect(() => {
    if (!markerPosition) return;

    // The marker is placed at the top of the info box for info box's fully display on mobile
    const NorthMarker = google.maps.geometry.spherical.computeOffset(markerPosition, 200, 0);
    // The marker is placed at the left top of the info box for info box's aesthetically display on larger screens
    const NorthWestMarker = google.maps.geometry.spherical.computeOffset(markerPosition, 150, 315);
    mapRef.current?.setCenter(isMobile ? NorthMarker : NorthWestMarker);
    mapRef.current?.setZoom(17);
  }, [markerPosition, isMobile]);

  useEffect(() => {
    const fetchGeoData = async () => {
      setIsPipeLineLoading(true);
      if (!pipelineURL) {
        return;
      }

      const result = await fetchGeoDataFromURLWithTimeout(pipelineURL, CMS_PIPELINE_FETCH_TIMEOUT_THRESHOLD);
      if (!(result === 'timeout' || result === 'error' || !result)) {
        setGeoData(result);
      }

      setIsPipeLineLoading(false);
    };

    fetchGeoData();
  }, []);

  useEffect(() => {
    if (!mapRef.current || !geoData) return;
    setIsPipeLineLoading(true);
    mapRef.current.data.addGeoJson(geoData);
    mapRef.current.data.setStyle({
      strokeColor: THEME_PIPELINE_STROKE_COLOR,
      strokeWeight: 2,
    });
    const shortenedLinesCoords = refineData(geoData, POINT_PERCENTAGE_PER_LINE);
    setLines(shortenedLinesCoords);
    setIsPipeLineLoading(false);
  }, [mapRef.current, geoData]);

  const pipelineWording = isNearPipeline ? PIPELINE_WORDING.found : PIPELINE_WORDING.unFound;

  return (
    <Flex
      background="background"
      className={clsx(
        { [dawnTheme]: theme === 'dawn' },
        { [midnightTheme]: theme === 'midnight' },
        { [duskTheme]: theme === 'dusk' }
      )}
    >
      <Container>
        {!isPipeLineLoading && isMapLoaded ? (
          <Flex className={container}>
            {!address ? (
              <Flex className={searchBarWrapper}>
                <Box className={searchInputWrapper}>
                  <StandaloneSearchBox bounds={MAP_SEARCH_BOUNDS} onLoad={onSBLoad} onPlacesChanged={onPlacesChanged}>
                    <Box className={searchInput}>
                      <TextInput icon={'search'} placeholder="Enter an address" ref={inputRef} theme={theme} />
                    </Box>
                  </StandaloneSearchBox>
                </Box>

                <Button className={searchButton} label="Search" onClick={onSearchButtonClick} />
              </Flex>
            ) : (
              <StandaloneSearchBox bounds={MAP_SEARCH_BOUNDS} onLoad={onSBLoad} onPlacesChanged={onPlacesChanged}>
                <input hidden={true} ref={inputRef} />
              </StandaloneSearchBox>
            )}
            <Box className={mapWrapper} position="relative">
              <GoogleMap
                center={INITIAL_CENTER}
                mapContainerStyle={{
                  borderRadius: '10px',
                  position: 'absolute',
                  inset: '0',
                }}
                onLoad={(loadedMap) => {
                  mapRef.current = loadedMap;
                }}
                options={{
                  mapId: THEME_MAP_ID,
                  fullscreenControl: false,
                }}
                zoom={INITIAL_ZOOM}
              >
                {markerPosition && isInfoBoxOpen && (
                  <InfoBox
                    options={{
                      // Move the info box to the top of the marker
                      pixelOffset: new google.maps.Size(0, -60),
                      disableAutoPan: true,
                      boxClass: infoBox,
                      closeBoxURL: '',
                      zIndex: 10,
                    }}
                    position={markerPosition}
                  >
                    <Flex className={infoBoxContainer}>
                      <Icon className={closeIcon} name="close" onClick={onInfoBoxCloseClick} />
                      <Flex className={infoBoxFrame}>
                        <Flex className={infoBoxHeading}>
                          <Icon
                            className={clsx(
                              infoBoxIconSize,
                              isNearPipeline ? infoBoxIconColorSuccess : infoBoxIconColorError
                            )}
                            name={pipelineWording.icon.name}
                          />
                          <Text color="default" style="body1" weight="bold">
                            {pipelineWording.heading}
                          </Text>
                        </Flex>
                        {pipelineWording.contentBold && (
                          <Text className={textBody} color="default" style="body3" weight="bold">
                            {pipelineWording.contentBold}
                          </Text>
                        )}
                        <Text className={textBody} color="default" style="body3">
                          {pipelineWording.content}{' '}
                          {pipelineWording.contentLink && (
                            <Link className={contentLink} href={pipelineWording.contentLink.url}>
                              {pipelineWording.contentLink.text}
                            </Link>
                          )}
                        </Text>
                        <Text className={textBody} color="default" fontStyle="italic" style="body3">
                          {`${pipelineWording.contentItalic}`}
                        </Text>
                      </Flex>
                      <Box className={toolTip}></Box>
                    </Flex>
                  </InfoBox>
                )}
              </GoogleMap>
            </Box>
          </Flex>
        ) : (
          <Flex className={container} justifyContent="center">
            <LoadingSpinner size="xlarge" theme={theme} />
          </Flex>
        )}
      </Container>
    </Flex>
  );
};

export const PipelineLocatorBlock = memo(Component);
