'use client';

import * as Select from '@radix-ui/react-select';
import { Box, FieldLabel, Flex, Icon, Text } from '@ui/components';
import clsx from 'clsx';
import _ from 'lodash';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { dawnTheme } from '../../../styles/theme.css';

import * as styles from './dropdown.css';

import type { Dropdown as DropdownT } from '@solstice/types';

export const Dropdown = ({
  disabled,
  error,
  errorMessage,
  hint,
  icon,
  items,
  label,
  optional,
  placeholder,
  theme,
  onChange,
}: DropdownT.Props) => {
  const [value, setValue] = useState('');

  const fieldId = label ? `${_.kebabCase(label)}` : uuidv4();

  const onValueChange = (value: string) => {
    if (!value) return;
    setValue(value);
    onChange && onChange(value);
  };

  const dropdownItems = items?.filter((item) => item !== '');

  return (
    <Box className={theme === 'dawn' && dawnTheme}>
      <FieldLabel fieldId={fieldId} label={label} optional={optional} />
      <Select.Root aria-labelledby={label} disabled={disabled} onValueChange={onValueChange} value={value}>
        <Select.Trigger
          className={clsx(
            disabled ? styles.triggerDisabled : styles.triggerEnabled,
            error ? styles.triggerError : null,
            styles.trigger
          )}
          id={fieldId}
        >
          {icon && <Icon className={disabled ? styles.iconDisabled : styles.iconEnabled} name={icon} />}
          <Box className={styles.value}>
            <Select.Value aria-label={value} placeholder={placeholder}>
              <Text style={'body2'}>{value}</Text>
            </Select.Value>
          </Box>
          <Icon
            className={clsx(disabled ? styles.iconDisabled : styles.iconEnabled, styles.iconExpand)}
            name={'expand_more'}
          />
        </Select.Trigger>
        <Select.Content className={styles.content} position="popper">
          <Select.Viewport>
            {dropdownItems.map((item, i) => (
              <Select.Item className={styles.item} key={`${fieldId} ${i}`} value={item}>
                <Text style={'body2'}>{item}</Text>
              </Select.Item>
            ))}
          </Select.Viewport>
        </Select.Content>
      </Select.Root>
      <Flex>
        {error ? (
          <Text color={'error'} style={'body3'}>
            {errorMessage}
          </Text>
        ) : (
          <Text color={'contrast'} style={'body3'}>
            {hint}
          </Text>
        )}
      </Flex>
    </Box>
  );
};
