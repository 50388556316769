import { sprinkles } from '@style-system/atoms/sprinkles.css';
import { clsx, type ClassValue } from 'clsx';
import { createElement, forwardRef } from 'react';

import type { Sprinkles } from '@style-system/atoms/sprinkles.css';

type HTMLProperties<T = HTMLElement> = Omit<
  React.AllHTMLAttributes<T>,
  'as' | 'className' | 'color' | 'height' | 'width' | 'color' | 'cursor'
>;

type Props = Sprinkles &
  HTMLProperties & {
    as?: React.ElementType;
    className?: ClassValue;
  };

const Box = forwardRef<HTMLElement, Props>(({ as = 'div', className, ...props }: Props, ref) => {
  const atomProps: Record<string, unknown> = {};
  const nativeProps: Record<string, unknown> = {};

  /* eslint-disable no-restricted-syntax */
  for (const key in props) {
    if (sprinkles.properties.has(key as keyof Sprinkles)) {
      atomProps[key] = props[key as keyof typeof props];
    } else {
      nativeProps[key] = props[key as keyof typeof props];
    }
  }

  const atomicClasses = sprinkles(atomProps);

  return createElement(as, {
    className: clsx(atomicClasses, className),
    ref,
    ...nativeProps,
  });
});

export type BoxProps = Parameters<typeof Box>[0];

Box.displayName = 'Box';

export { Box };
