import { ALL_CATEGORY } from '../../constants';

import type * as FaqItem from '@solstice/types/src/sanity/atoms/faq-item';

type Args = {
  activeCategory: string;
  items?: FaqItem.Props[];
};

export const filterAccordionItems = ({ activeCategory, items }: Args) => {
  const filteredItems = items?.filter((item) => {
    if (activeCategory === ALL_CATEGORY) {
      return true;
    }

    return item.category._ref === activeCategory;
  });

  return filteredItems;
};
