'use client';
import clsx from 'clsx';
import { useState } from 'react';

import { dawnTheme, duskTheme } from '../../../styles/theme.css';
import { Accordion } from '../../accordion';
import { Box } from '../../box';
import { Container } from '../../container';
import { Flex } from '../../flex';
import { Flourish } from '../../flourish';
import { Grid } from '../../grid';
import { Heading } from '../../heading';
import { LoadingSpinner } from '../../loading-spinner';
import { RadioGroup } from '../../radio-group';
import { Text } from '../../text';

import { ALL_CATEGORY } from './constants';
import * as styles from './faq.css';
import { filterAccordionItems } from './utils/filter-accordion-items';
import { filterCategories } from './utils/filter-categories';

import type { FaqBlock as FaqBlockT } from '@solstice/types';

export const FaqBlock = ({ title, flourish, body, categories, items, theme }: FaqBlockT.Props) => {
  const shouldShowAll = categories?.length > 1;
  const defaultCategory = shouldShowAll ? ALL_CATEGORY : categories?.[0]?._id;
  const [activeCategory, setActiveCategory] = useState(defaultCategory);

  const radioCategories = [
    ...(shouldShowAll ? [{ title: 'All', value: ALL_CATEGORY }] : []),
    ...categories.map((category) => ({ title: category.title, value: category._id })),
  ];

  const onChange = (value: string) => {
    setActiveCategory(value);
  };

  const filteredRadioCategories = filterCategories({ categories: radioCategories, items });

  const filteredAccordionItems = filterAccordionItems({ activeCategory, items });

  const shouldShowLoadingSpinner = items?.length === 0 || !items;

  return (
    <Box
      background="background"
      className={clsx(theme === 'dawn' && dawnTheme, theme === 'dusk' && duskTheme)}
      paddingY="space-j"
    >
      <Container>
        <Grid columns={12}>
          <Flex className={styles.content} direction="column" textAlign="center">
            <Flex className={styles.heading} justify="center" position="relative">
              <Flex className={styles.flourish} justify="center">
                <Flourish name={flourish} />
              </Flex>
              <Heading aria-label={title} as="h2" color="contrast" marginBottom="space-c" style="h3">
                {title}
              </Heading>
            </Flex>
            {body && (
              <Text className={styles.fullWidth} color="contrast" marginBottom="space-g" style="body2">
                {body}
              </Text>
            )}
            {shouldShowLoadingSpinner ? (
              <Flex className={styles.fullWidth} justifyContent="center" marginBottom="space-g">
                <LoadingSpinner size="large" theme={theme} />
              </Flex>
            ) : (
              <>
                {filteredRadioCategories && (
                  <Flex alignItems="center" className={styles.fullWidth} marginBottom="space-g">
                    <RadioGroup
                      ariaLabel="FAQ category"
                      defaultValue={defaultCategory}
                      items={filteredRadioCategories}
                      onChange={onChange}
                      theme={theme}
                    />
                  </Flex>
                )}
                {filteredAccordionItems && (
                  <Flex className={styles.fullWidth} direction="column" gap="space-d">
                    {filteredAccordionItems.map((item) => (
                      <Accordion
                        _type="accordion"
                        body={item.content}
                        key={item.title}
                        theme={theme}
                        title={item.title}
                      />
                    ))}
                  </Flex>
                )}
              </>
            )}
          </Flex>
        </Grid>
      </Container>
    </Box>
  );
};
