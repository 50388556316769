import { getImage } from '@solstice/lib';
import { Box } from '@ui/components/box';
import Image from 'next/image';

import { img, imageContainer, imageSubContainer } from './index.css';

import type { SanityImageProps } from 'packages/types/src';

type Props = {
  image: SanityImageProps;
};

export const HeroImage = ({ image }: Props) => {
  return (
    <Box className={imageContainer}>
      <Box className={imageSubContainer}>
        <Image alt={image.alt} className={img} fill src={getImage(image).url()} />
      </Box>
    </Box>
  );
};
