'use client';
import { useId } from 'react';
import { Controller } from 'react-hook-form';

import { Flex } from '../flex';
import { Dropdown } from '../form/dropdown';
import { FieldLabel } from '../form/field-label';
import { RadioGroup } from '../radio-group';
import { Text } from '../text';

import { FormCheckBox } from './components/form-checkbox';
import { FormFileInput } from './components/form-file-input';
import { FormTextInput } from './components/form-text-input';

import type { FormField as FormFieldT, Theme } from '@solstice/types';
import type { Control } from 'react-hook-form';
interface Props {
  theme: Exclude<Theme.Props, 'tasGas'>;
  field: FormFieldT.Props;
  control: Control;
  fieldsetKey: string;
}

const generateRequiredRule = (isMandatory: boolean) => ({ required: isMandatory ? 'This field is required' : false });

export const FormField = ({ theme, control, field, fieldsetKey }: Props) => {
  const label = `${fieldsetKey}:${field._key}`;

  switch (field.fieldType) {
    case 'text':
    case 'email':
    case 'phone':
    case 'number':
    case 'date':
      return (
        <FormTextInput
          control={control}
          field={field}
          generateRequiredRule={generateRequiredRule}
          name={label}
          theme={theme}
          type={field.fieldType}
        />
      );
    case 'dropdown':
      return (
        <Controller
          control={control}
          name={label}
          render={({ field: { onChange }, fieldState: { error } }) => (
            <Dropdown
              error={!!error}
              errorMessage={error?.message}
              items={field.dropdownItems || []}
              label={field.label}
              onChange={onChange}
              optional={!field.isMandatory}
              placeholder={field.placeholderText}
            />
          )}
          rules={generateRequiredRule(field.isMandatory)}
        />
      );
    case 'radio':
      return (
        <Controller
          control={control}
          name={label}
          render={({ field: { onChange }, fieldState: { error } }) => (
            <Flex direction="column" gap="space-a">
              <FieldLabel fieldId={field._key} label={field.label} optional={!field.isMandatory} />
              <RadioGroup
                items={field.radioItems?.map((item) => ({ title: item, value: item }))}
                key={field._key}
                onChange={onChange}
                variant="left"
              />
              {!!error && (
                <Text color={'error'} style={'body3'}>
                  {error.message}
                </Text>
              )}
            </Flex>
          )}
          rules={generateRequiredRule(field.isMandatory)}
        />
      );
    case 'checkbox': {
      const itemsWithID = field?.checkboxItems?.map((item) => ({
        id: useId(),
        value: item,
      }));

      return (
        <FormCheckBox
          control={control}
          groupName={field.label}
          items={itemsWithID}
          name={label}
          optional={!field.isMandatory}
        />
      );
    }
    case 'file':
      return (
        <FormFileInput
          control={control}
          isMandatory={field.isMandatory}
          label={field.label}
          name={label}
          theme={theme}
        />
      );
  }

  return null;
};
