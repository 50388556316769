import clsx from 'clsx';

import { Box } from '../box';

import { loader } from './loadingSpinner.css';

import type { Theme } from '@solstice/types';

type loadingSpinnerProps = {
  size?: 'xSmall' | 'small' | 'medium' | 'large' | 'xlarge';
  theme?: Theme.Props;
  className?: string;
};

export const LoadingSpinner = ({ size = 'medium', theme, className }: loadingSpinnerProps) => {
  return <Box aria-label="loading" as="span" className={clsx(loader({ size, theme }), className)}></Box>;
};
