/* eslint-disable react/prop-types */
import { type QuickLinks } from '@solstice/types';
import { Grid } from '@ui/components/grid';
import Link from '@ui/components/next-link';

import { Box } from '../../box';
import { Container } from '../../container';
import { Flex } from '../../flex';
import { Icon } from '../../icon';
import { Text } from '../../text';

import { content, iconStyle, link, underline, wrapper } from './quickLinks.css';

export const QuickLinksBlock = ({ links }: QuickLinks.Props) => (
  <Container>
    <Grid columns={12}>
      <Flex className={wrapper}>
        {links &&
          links.map(({ title, slug, icon }) => {
            if (!slug) return;
            return (
              <Link className={link} href={slug} key={slug}>
                <Flex className={content}>
                  <Icon className={iconStyle} name={icon} />
                  <Text style={'body2'}>{title}</Text>
                </Flex>
                <Box className={underline} />
              </Link>
            );
          })}
      </Flex>
    </Grid>
  </Container>
);
