'use client';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Flex } from '@ui/components/flex';
import { Icon } from '@ui/components/icon';
import { Text } from '@ui/components/text';

import { chevronDown, icon, trigger, triggerContent } from './index.css';

const Trigger = ({ title }: { title: string }) => (
  <DropdownMenu.Trigger asChild>
    <button aria-label={title} className={trigger}>
      <Flex className={triggerContent}>
        <Text color="none" style="body3" whiteSpace="nowrap">
          {title}
        </Text>
        <Flex align="center" className={chevronDown} justify="center">
          <Icon className={icon} name="expand_more" />
        </Flex>
      </Flex>
    </button>
  </DropdownMenu.Trigger>
);

export default Trigger;
