'use client';

import * as RadixRadio from '@radix-ui/react-radio-group';
import clsx from 'clsx';

import { dawnTheme } from '../../styles/theme.css';
import { Flex } from '../flex';

import { RadioItem } from './components/radio-item';
import * as styles from './radioGroup.css';

import type { Theme } from '@solstice/types';

type Props = {
  ariaLabel?: string;
  defaultValue?: string;
  onChange: (value: string) => void;
  items?: { title: string; value?: string }[];
  theme?: Theme.Props;
  variant?: 'center' | 'left';
  labelStyle?: 'body1' | 'body2' | 'body3';
};

export const RadioGroup = ({
  ariaLabel,
  defaultValue,
  items,
  onChange,
  theme = 'midnight',
  variant = 'center',
  labelStyle,
}: Props) => (
  <RadixRadio.Root
    aria-label={ariaLabel}
    className={clsx(styles.root({ variant }), theme === 'dawn' && dawnTheme)}
    defaultValue={defaultValue}
    onValueChange={onChange}
  >
    <Flex className={styles.radioGroup} direction="row">
      {items?.map((item, i) => (
        <RadioItem
          key={`${item} ${i}`}
          label={item.title}
          labelStyle={labelStyle}
          theme={theme}
          value={item.value}
          variant={variant}
        />
      ))}
    </Flex>
  </RadixRadio.Root>
);
