export const CircleLine = () => (
  <svg fill="none" height="50" viewBox="0 0 50 50" width="50" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M25 49C38.2548 49 49 38.2548 49 25C49 11.7452 38.2548 1 25 1C11.7452 1 1 11.7452 1 25C1 38.2548 11.7452 49 25 49Z"
      fill="#9646FF"
      fillRule="evenodd"
    />
    <path d="M22.671 24.839H28.32V27.17H22.671V24.839Z" fill="#200158" />
  </svg>
);
