export const Lightbulb = () => (
  <svg fill="none" height="64" viewBox="0 0 64 64" width="64" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.9155 55.4366C23.9155 54.192 24.9244 53.1831 26.169 53.1831H38.7887C40.0333 53.1831 41.0423 54.192 41.0423 55.4366C41.0423 56.6812 40.0333 57.6901 38.7887 57.6901H26.169C24.9244 57.6901 23.9155 56.6812 23.9155 55.4366Z"
      fill="#FF5647"
    />
    <path
      d="M23.9155 61.7465C23.9155 60.5019 24.9244 59.493 26.169 59.493H38.7887C40.0333 59.493 41.0423 60.5019 41.0423 61.7465C41.0423 62.9911 40.0333 64 38.7887 64H26.169C24.9244 64 23.9155 62.9911 23.9155 61.7465Z"
      fill="#FF5647"
    />
    <path
      d="M50.9577 15.3239C50.9577 19.904 48.5348 24.0147 44.6938 26.8228C44.3949 27.1099 44.0949 27.3933 43.7988 27.6729C42.515 28.8854 41.3056 30.0275 40.5915 31.0986C39.2394 32.7512 38.365 37.3105 42.3944 41.9155C45.5493 45.5211 42.9953 48.5258 40.1408 49.5775L33.831 49.928V29.2641C34.9087 28.6406 35.6338 27.4754 35.6338 26.1408C35.6338 24.1495 34.0195 22.5352 32.0282 22.5352C30.0368 22.5352 28.4225 24.1495 28.4225 26.1408C28.4225 27.4755 29.1476 28.6407 30.2254 29.2641V49.9333L23.4648 49.5775C21.662 48.8263 18.7775 46.6028 21.662 41.9155C24.4224 37.4297 24.277 36.1141 24.0571 34.1257C23.9898 33.517 23.9155 32.8452 23.9155 32C23.9155 30.4626 22.3791 28.8996 20.6028 27.0925L20.5144 27.0026C16.5286 24.1918 14 20.0028 14 15.3239C14 6.86076 22.2733 0 32.4789 0C42.6845 0 50.9577 6.86076 50.9577 15.3239Z"
      fill="#FF5647"
    />
  </svg>
);
