export enum SiteName {
  Solstice = 'Solstice',
  SolsticeVictoria = 'Solstice Victoria',
  TasGasNetworks = 'Tas Gas Networks',
  GasPipelinesVictoria = 'Gas Pipelines Victoria',
}

const site = process.env.NEXT_PUBLIC_SITE as SiteName;
export const isGPV = site === SiteName.GasPipelinesVictoria;
export const isTasGas = site === SiteName.TasGasNetworks;
export const isTasGasOrGPV = site === SiteName.GasPipelinesVictoria || site === SiteName.TasGasNetworks;
export const isTasmania = site === SiteName.Solstice || site === SiteName.TasGasNetworks;
export const isVictoria = site === SiteName.SolsticeVictoria || site === SiteName.GasPipelinesVictoria;

export enum SiteTheme {
  Solstice,
  TasGas,
}

export interface Site {
  name: SiteName;
  logo: unknown;
  description: string;
}

export const Sites: Site[] = [
  {
    name: SiteName.Solstice,
    logo: () => 'S',
    description: 'The Solstice website',
  },
  {
    name: SiteName.SolsticeVictoria,
    logo: () => '🦘',
    description: 'The Solstice Victoria website',
  },
  {
    name: SiteName.TasGasNetworks,
    logo: () => '🔥',
    description: 'Tas Gas Networks website',
  },
  {
    name: SiteName.GasPipelinesVictoria,
    logo: () => '⛽️',
    description: 'Gas Pipelines Victoria website',
  },
];

export const GlobalSite = 'Global';
