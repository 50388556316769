import * as RadixRadio from '@radix-ui/react-radio-group';
import clsx from 'clsx';

import { Flex } from '../../../flex';
import { Text } from '../../../text';

import * as styles from './radioItem.css';

import type { Theme } from '@solstice/types';

type Props = {
  label: string;
  theme: Theme.Props;
  value?: string;
  variant?: 'center' | 'left';
  labelStyle?: 'body1' | 'body2' | 'body3';
};

export const RadioItem = ({ label, theme, value, variant = 'left', labelStyle = 'body2' }: Props) => (
  <Flex className={styles.testContainer({ variant })}>
    <RadixRadio.Item
      className={clsx(styles.radioItemWrapper, theme === 'dawn' && styles.dawnThemeBorder)}
      id={label}
      value={value || label}
    >
      <RadixRadio.Indicator className={styles.radioItemIndicator} />
    </RadixRadio.Item>
    <Text as="label" className={styles.radioItemLabel} color="contrast" htmlFor={label} style={labelStyle}>
      {label}
    </Text>
  </Flex>
);
