'use client';

import * as RadixDialog from '@radix-ui/react-dialog';
import { categories, isGPV, isTasGas, isTasGasOrGPV } from '@solstice/constants';
import { Box } from '@ui/components/box';
import { Button } from '@ui/components/button';
import { Container } from '@ui/components/container';
import { DropdownMenu } from '@ui/components/dropdown-menu';
import { Flex } from '@ui/components/flex';
import { Icon } from '@ui/components/icon';
import { GPVLogos, Logos, TasGasLogos } from '@ui/components/logo';
import Link from '@ui/components/next-link';
import { Text } from '@ui/components/text';
import { filter, map } from 'lodash/fp';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

import { trigger } from '../dropdown-menu/components/trigger/index.css';

import { GeographySelector } from './components/geography-selector';
import { MobileNavMenu } from './components/mobileNavMenu';
import {
  animation,
  container,
  desktopContent,
  dropdownMenus,
  icon,
  link,
  mobileContent,
  navBarFirstRow,
  navBarSecondRow,
  navBarTopItem,
  tasGasGPVLink,
  widthController,
} from './navBar.css';

import type { SiteName } from '@solstice/constants';
import type { NavBar as NavBarT, Page as PageT } from '@solstice/types';

export const NavBar = ({ pages }: NavBarT.Props) => {
  const pathname = usePathname();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  useEffect(() => {
    if (mobileNavOpen) {
      setMobileNavOpen(false);
    }
  }, [pathname]);

  return (
    <Flex as="nav" className={container}>
      <Flex className={widthController}>
        <Container>
          <Box className={navBarFirstRow}>
            <Link href="https://tasgasnetworks.com.au/network-alerts">
              <Text className={navBarTopItem} color={isTasGasOrGPV ? 'contrast' : 'none'} style="body3">
                Gas Network Alerts
              </Text>
            </Link>
            <Link href="/contact-us">
              <Text className={navBarTopItem} color={isTasGasOrGPV ? 'contrast' : 'none'} style="body3">
                Contact us
              </Text>
            </Link>
            <GeographySelector />
          </Box>
        </Container>
        <Flex className={navBarSecondRow}>
          <Container>
            <Box className={desktopContent}>
              <Link href="/">{isTasGas ? <TasGasLogos.Logo /> : isGPV ? <GPVLogos.Logo /> : <Logos.Logo />}</Link>
              <Flex className={dropdownMenus}>
                {map(
                  (category) => {
                    if (category.singleton) {
                      return (
                        <Link href={`/${category.value}`}>
                          <button aria-label={category.title} className={trigger}>
                            <Text color="none" style="body3" whiteSpace="nowrap">
                              {category.title}
                            </Text>
                          </button>
                        </Link>
                      );
                    }
                    const items = filter((page: PageT.Props) => page.category === category.value, pages);

                    return <DropdownMenu category={category} key={category.value} pages={items} />;
                  },
                  categories[(process.env.NEXT_PUBLIC_SITE ?? 'Solstice') as SiteName]
                )}
              </Flex>
              <Flex gap="space-b">
                {/* <Button label="Login" showIcon={false} type="secondary" /> */}
                {process.env.NEXT_PUBLIC_SITE === 'Solstice' && (
                  <Button href="/apply-online" label="Join now" showIcon={false} type="primary" />
                )}
              </Flex>
            </Box>
            <RadixDialog.Root onOpenChange={(open: boolean) => setMobileNavOpen(open)} open={mobileNavOpen}>
              <Flex align="center" className={mobileContent} flex="1" justify="space-between">
                <Link href="/">{isTasGas ? <TasGasLogos.Logo /> : isGPV ? <GPVLogos.Logo /> : <Logos.Logo />}</Link>
                <Flex align="center" gap="space-e">
                  {/* <Link className={link} href="placeholder">
                    <Text color="none" style="body3">
                      Login
                    </Text>
                  </Link> */}
                  {process.env.NEXT_PUBLIC_SITE === 'Solstice' && (
                    <Link className={link} href="/apply-online">
                      <Text color="none" style="body3">
                        Join
                      </Text>
                    </Link>
                  )}
                  <RadixDialog.Trigger className={isTasGasOrGPV ? tasGasGPVLink : link}>
                    <Icon className={icon} name="menu" />
                  </RadixDialog.Trigger>
                </Flex>
              </Flex>
              <RadixDialog.Overlay />
              <RadixDialog.Content className={animation}>
                <MobileNavMenu pages={pages} setMobileNavOpen={setMobileNavOpen} />
              </RadixDialog.Content>
            </RadixDialog.Root>
          </Container>
        </Flex>
      </Flex>
    </Flex>
  );
};
