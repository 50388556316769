import * as CheckboxInput from '@radix-ui/react-checkbox';
import { Flex } from '@ui/components/flex';
import { Icon } from '@ui/components/icon';
import { Text } from '@ui/components/text';
import { useId } from 'react';

import { checkbox, checkboxLabel, checkboxWrapper, container } from './checkbox.css';

import type { CheckBoxInput as CheckBoxInputT } from '@solstice/types';

type defaultProps = {
  defaultChecked?: CheckboxInput.CheckedState;
};

export const CheckBox = ({
  label,
  hint,
  checked = false,
  error,
  disabled,
  defaultChecked,
  onChange,
}: CheckBoxInputT.Props & defaultProps) => {
  const id = useId();

  return (
    <Flex className={container}>
      <Flex className={checkboxWrapper}>
        <CheckboxInput.Root
          checked={checked}
          className={checkbox({ checked, error: !!error, disabled })}
          defaultChecked={defaultChecked}
          disabled={disabled}
          id={id}
          onCheckedChange={onChange}
          value={label}
        >
          <CheckboxInput.Indicator>{(defaultChecked || checked) && <Icon name="check" />}</CheckboxInput.Indicator>
        </CheckboxInput.Root>
        <Text as="label" className={checkboxLabel} color="contrast" htmlFor={id} style="body2">
          {label}
        </Text>
      </Flex>

      {hint && (
        <Text color="contrast" style="body3">
          {hint}
        </Text>
      )}
    </Flex>
  );
};
