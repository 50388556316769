import { PortableText } from '@portabletext/react';
import { getFile, getImage } from '@solstice/lib';
import { values } from 'lodash';
import Image from 'next/image';
import Link from '@ui/components/next-link';

import { Box } from '../box';
import { Button } from '../button';
import { Flex } from '../flex';
import { Icon } from '../icon';
import { Text } from '../text';

import type { colors } from '../../styles/theme.css';
import type { PortableTextReactComponents } from '@portabletext/react';
import type { RichText as RichTextT, Download, SanityImageProps } from '@solstice/types';

const componentMap: Partial<PortableTextReactComponents> = {
  types: {
    image: ({ value }: { value: SanityImageProps }) => {
      return (
        <Box position="relative">
          <Image alt={value.alt} fill src={getImage(value).url()} />
        </Box>
      );
    },
    download: ({ value }: { value: Download.Props }) => (
      <Box marginY="space-a">
        <Button
          href={getFile(value)?.url}
          icon={
            <Box marginLeft="space-a">
              <Icon name="download" />
            </Box>
          }
          label={value.title}
          target="_blank"
          type="primary"
        />
      </Box>
    ),
  },

  block: {
    normal: ({ children }) => (
      <Text color="none" style="body2">
        {children}
      </Text>
    ),
  },

  hardBreak: () => <Box id="test-test" marginTop="space-c" />,

  list: {
    bullet: ({ children }) => (
      <Flex as="ul" direction="column" paddingLeft="space-a">
        {children}
      </Flex>
    ),
    number: ({ children }) => (
      <Flex as="ol" counterReset="item" direction="column" display="flex" listStyle="number" paddingLeft="space-a">
        {children}
      </Flex>
    ),
  },

  listItem: {
    bullet: ({ children }) => (
      <Box aria-label={children?.toString()} as="li" listStyle="disc" listStylePosition="inside">
        <Text as="span" color="none" style="body2">
          {children}
        </Text>
      </Box>
    ),

    number: ({ children }) => (
      <Box aria-label={children?.toString()} as="li" listStyle="number" listStylePosition="inside">
        <Text as="span" color="none" style="body2">
          {children}
        </Text>
      </Box>
    ),
  },

  marks: {
    link: ({ children, value }) => {
      const rel = !value?.href?.startsWith('/') ? 'noreferrer noopener' : undefined;
      return (
        <Link href={value?.href ?? '#'} rel={rel}>
          <Text color="secondary" style="none" textDecoration="underline">
            {children}
          </Text>
        </Link>
      );
    },
  },
};

export const RichText = ({ color, data }: { color?: keyof typeof colors; data: RichTextT.Props }) => {
  return (
    <Flex color={color} direction="column" gap="space-a">
      <PortableText components={componentMap} value={values(data)} />
    </Flex>
  );
};
