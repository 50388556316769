import { Flex } from '@ui/components/flex';
import { Text } from '@ui/components/text';
import clsx from 'clsx';
import { isTasGasOrGPV, isTasmania, isVictoria } from 'packages/constants';

import { activeSite, geographyOption, geographySelector } from './geographySelector.css';

export const GeographySelector = () => {
  if (isTasGasOrGPV) {
    return null;
  }

  return (
    <Flex className={geographySelector}>
      <Text color={isTasGasOrGPV ? 'contrast' : 'none'} style="body2">
        <a href="/">
          <Text
            className={clsx(isTasmania && activeSite, geographyOption)}
            color={isTasGasOrGPV ? 'contrast' : 'none'}
            style="body2"
          >
            TAS
          </Text>
        </a>{' '}
        /{' '}
        <a href="/vic">
          <Text
            className={clsx(isVictoria && activeSite, geographyOption)}
            color={isTasGasOrGPV ? 'contrast' : 'none'}
            style="body2"
          >
            VIC
          </Text>
        </a>
      </Text>
    </Flex>
  );
};
