import { Icon as MaterialIcon } from '@ui/components/icon';
import clsx from 'clsx';
import { isTasGasOrGPV } from 'packages/constants/src';

import { Box } from '../box';
import { Flower } from '../brand-icons';
import { Flex } from '../flex';
import { Grid } from '../grid';
import { Text } from '../text';

import { circleIcon, iconBox, iconCircleBox, iconSize, list } from './list.css';

import type { List as ListT, MaterialSymbol, Theme, BrandIcon } from '@solstice/types';

type ListProps = {
  items?: ListT.Props;
  bulletIcon?: React.ElementType;
  type?: 'circle' | 'flower';
  theme?: Theme.Props;
};

type IconListProps = {
  type: 'brand' | 'icon';
  iconName: MaterialSymbol | '';
  brandName: BrandIcon | '';
  isCircle: boolean;
};

export const List = ({ items, bulletIcon = Flower, type = 'flower', theme = 'midnight' }: ListProps) => {
  const BulletIcon = bulletIcon;

  const getIcon = ({ type, iconName, brandName, isCircle }: IconListProps) => {
    if (isCircle && type === 'icon') {
      return (
        <Flex className={clsx(iconBox, iconCircleBox({ theme }))}>
          <MaterialIcon brandName="" className={clsx(iconSize, circleIcon)} iconType={type} name={iconName} />
        </Flex>
      );
    }
    if (type === 'brand' && brandName) {
      return (
        <Flex className={iconBox}>
          <MaterialIcon brandName={brandName} className={clsx(iconSize, circleIcon)} iconType={type} name="" />
        </Flex>
      );
    }

    return (
      <Flex className={iconBox}>
        {isTasGasOrGPV ? <MaterialIcon iconType="icon" name="chevron_right" /> : <BulletIcon className={iconSize} />}
      </Flex>
    );
  };

  return (
    <Flex as="ul" direction="column" gap="space-c">
      {items?.map(({ title, text, icon, brandIcon, iconType }) => {
        const isCircleIcon = !!(icon && type === 'circle');
        return (
          <Grid aria-label={title} as="li" className={list} gap="space-b" key={`${title} ${text}`}>
            <Box style={{ height: '1em' }}>
              {getIcon({ type: iconType, iconName: icon || '', brandName: brandIcon || '', isCircle: isCircleIcon })}
            </Box>
            <Flex direction="column" gap="space-a">
              <Text as="h3" color="contrast" style="body1" weight="bold">
                {title}
              </Text>
              <Text color="contrast" style="body2">
                {text}
              </Text>
            </Flex>
          </Grid>
        );
      })}
    </Flex>
  );
};
