import { ALL_CATEGORY } from '../../constants';

import type { FaqItem } from '@solstice/types';

type Category = {
  title: string;
  value: string;
};

type Args = {
  categories: Category[];
  items?: FaqItem.Props[];
};

export const filterCategories = ({ categories, items }: Args) => {
  const filteredCategories = categories?.filter((category) => {
    if (category.value === ALL_CATEGORY) {
      return true;
    }

    return items?.some((item) => item.category._ref === category.value);
  });

  return filteredCategories;
};
