import clsx from 'clsx';

import { dawnTheme, duskTheme } from '../../../styles/theme.css';
import { Box } from '../../box';
import { Button } from '../../button';
import { Container } from '../../container';
import { Flex } from '../../flex';
import { Grid } from '../../grid';
import { Heading } from '../../heading';
import { List } from '../../list';
import { Text } from '../../text';

import { btnColorHome, textOnHome } from './contentList.css';

import type { ContentList } from '@solstice/types';

export const ContentListBlock = ({
  title,
  subtitle,
  content,
  cta,
  list,
  theme = 'midnight',
  home,
}: ContentList.Props) => {
  return (
    <Box
      background={!home ? 'background' : undefined}
      className={clsx(theme === 'dawn' && dawnTheme, theme === 'dusk' && duskTheme)}
      paddingY="space-j"
    >
      <Container asChild>
        <Grid columns={{ mobile: 1, tablet: 1, desktop: 2 }} gap="space-g">
          <Flex align="flex-start" direction="column" gap="space-c">
            <Heading as="h2" color="contrast" style="h3">
              {title}
            </Heading>
            <Flex direction="column" gap="space-a">
              <Text className={home && textOnHome} color={!home ? 'contrast' : 'none'} style="body1" weight="bold">
                {subtitle}
              </Text>
              {content && (
                <Text className={home && textOnHome} color={!home ? 'contrast' : 'none'} style="body2">
                  {content}
                </Text>
              )}
            </Flex>
            {cta && <Button className={home && btnColorHome} href={cta.link} label={cta.label} type="primary" />}
          </Flex>
          <List items={list} theme={theme} type="circle" />
        </Grid>
      </Container>
    </Box>
  );
};
