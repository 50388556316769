type Flourish = {
  src: string;
  alt: string;
  width: number;
  height: number;
};

type Flourishes = {
  [key: string]: Flourish;
};

export const flourishes: Flourishes = {
  kettle: { src: 'kettle.png', alt: '', width: 105, height: 105 },
  bulb: { src: 'light-bulb.png', alt: '', width: 100, height: 100 },
  windmill: { src: 'windmill.png', alt: '', width: 100, height: 100 },
  hat: { src: 'hard-hat.png', alt: '', width: 100, height: 100 },
  oven: { src: 'oven.png', alt: '', width: 100, height: 100 },
};
