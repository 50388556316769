import Link from '@ui/components/next-link';
import clsx from 'clsx';

import { Box } from '../box';
import { Flex } from '../flex';
import { Icon } from '../icon';
import { Text } from '../text';

import { tertiary, secondary, buttonRecipe, iconClass, noIcon } from './button.css';

import type { ButtonVariants } from './button.css';
import type { BoxProps } from '../box';
import type { TextVariants } from '../text/index.css';
import type { PropsWithChildren } from 'react';

export type ButtonProps = Omit<BoxProps, 'color'> &
  ButtonVariants &
  TextVariants & {
    label?: string;
    ariaLabel?: string;
    href?: string;
    target?: string;
    onClick?: () => void;
    icon?: React.ReactNode;
    showIcon?: boolean;
    disabled?: boolean;
    full?: boolean;
  };

export const Button = ({
  label,
  ariaLabel,
  href,
  target,
  onClick,
  type,
  icon,
  color,
  showIcon = true,
  className,
  disabled,
  full,
}: ButtonProps) => (
  <MaybeLink href={href} target={target}>
    <Flex
      align="center"
      aria-label={label || ariaLabel}
      as="button"
      className={clsx(
        type == 'secondary' && secondary,
        type == 'tertiary' && tertiary,
        buttonRecipe({ type }),
        !showIcon && noIcon,
        className
      )}
      disabled={disabled}
      gap="space-a"
      justify="center"
      onClick={onClick}
      style={full ? { width: '100%' } : undefined}
    >
      {label && (
        <Text color={color ?? 'none'} style="button" whiteSpace="nowrap">
          {label}
        </Text>
      )}
      {showIcon && (
        <Text color={color ?? 'none'}>
          <Box className={iconClass}>{icon ? icon : <Icon name="arrow_right_alt" />}</Box>
        </Text>
      )}
    </Flex>
  </MaybeLink>
);

const MaybeLink = ({ href, children, target }: PropsWithChildren<{ href?: string; target?: string }>) =>
  href ? (
    <Link href={href} target={target}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );
