import { acceptedFiles } from '@solstice/constants';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { FileInputLayout } from '../../../form/file-input';

import type { Theme } from '@solstice/types';
import type { Control } from 'react-hook-form';
export interface Props {
  label: string;
  name: string;
  isMandatory: boolean;
  control: Control;
  theme: Exclude<Theme.Props, 'tasGas'>;
}

const MAX_SIZE = 10 * 1024 * 1024;
const FILE_LIMIT = 4;

export const FormFileInput = ({ label, control, name, isMandatory, theme }: Props) => {
  const {
    formState: { isSubmitting },
  } = useForm();
  const [errorState, setErrorState] = useState<'fileTooBig' | 'unsupportedFile' | 'mandatory' | 'fileLimitExceed' | ''>(
    ''
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        if (error?.type === 'required' && (!value || value?.length == 0)) {
          setErrorState('mandatory');
        }
        const fileValidate = (newFiles: File[]) => {
          const validFiles = newFiles.filter((file) => {
            const fileType = file.type;
            return acceptedFiles.includes(fileType);
          });
          const validFileSize = newFiles.filter((file) => {
            return file.size < MAX_SIZE;
          });

          if (validFileSize.length !== newFiles.length) {
            setErrorState('fileTooBig');
            return;
          }
          if (newFiles.length !== validFiles.length) {
            setErrorState('unsupportedFile');
            return;
          }
          if (value && !(value?.length + newFiles?.length <= FILE_LIMIT)) {
            setErrorState('fileLimitExceed');
            return;
          }
          setErrorState('');
          if (value && value?.length !== 0) {
            onChange([...value, ...newFiles]);
            return;
          }
          onChange([...newFiles]);
          return;
        };

        return (
          <FileInputLayout
            error={errorState}
            files={value}
            isUploading={isSubmitting}
            label={label}
            name={name}
            onDrop={(e) => {
              e.preventDefault();
              setErrorState('');
              const newFiles = Array.from(e.dataTransfer.files as FileList);
              fileValidate(newFiles);
            }}
            onFileInputChange={(e) => {
              setErrorState('');
              const newFiles = Array.from(e.target.files as FileList);
              fileValidate(newFiles);
            }}
            onFileRemove={(index: number) => {
              const updatedFiles = [...value];
              updatedFiles.splice(index, 1);

              onChange(updatedFiles);
              if (updatedFiles?.length === 0 && isMandatory) {
                setErrorState('mandatory');
              }
            }}
            theme={theme}
          />
        );
      }}
      rules={isMandatory ? { required: 'document is required' } : undefined}
    />
  );
};
