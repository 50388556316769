import { colors } from '@style-system/theme.css';
import { Container } from '@ui/components/container';
import { Flex } from '@ui/components/flex';
import { HighlightText } from '@ui/components/highlight-text';
import { Suspense } from 'react';

import { HeroImage } from './components/hero-image';
import { Squiggle } from './components/squiggle';
import { container, nonImageContent, nonImageContentSpacer, wrapper } from './heroHeader.css';

import type { HeroHeader } from '@solstice/types';

export const HeroHeaderBlock = ({ title, image, address, home = false, AddressLookupComponent }: HeroHeader.Props) => {
  return (
    <Container>
      <Flex className={wrapper}>
        <Flex className={container}>
          <Squiggle />
          <HeroImage image={image} />
          <Flex className={nonImageContent}>
            <Flex className={nonImageContentSpacer}>
              <HighlightText as="h1" color="contrast" highlightColor={colors.primary900} value={title} />
              {address && AddressLookupComponent && (
                <Suspense>
                  <AddressLookupComponent home={home} />
                </Suspense>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Container>
  );
};
