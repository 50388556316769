import clsx from 'clsx';
import * as React from 'react';

import { Box } from '../box';

import { heading } from './heading.css';

import type { HeadingVariants } from './heading.css';
import type { BoxProps } from '../box';

export type HeadingProps = BoxProps & HeadingVariants;

export const Heading = React.forwardRef(
  ({ as, style, color, children, className, ...props }: HeadingProps, ref: React.Ref<HTMLElement>) => {
    return (
      <Box as={as ?? style} className={clsx([heading({ style, color }), className])} ref={ref} {...props}>
        {children}
      </Box>
    );
  }
);

Heading.displayName = 'Heading';
