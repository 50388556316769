import { SiteName } from './sites';

import type { Category } from 'packages/types';
const singleton = true;

export const categories: { [key in SiteName]: Category.Props[] } = {
  [SiteName.Solstice]: [
    { title: 'Different Energy', value: 'different-energy' },
    { title: 'Residential', value: 'moving' },
    { title: 'Business', value: 'business' },
    { title: 'Help & Support', value: 'help-and-support' },
    { title: 'About us', value: 'about-us' },
  ],
  [SiteName.TasGasNetworks]: [
    { title: 'Pipeline Locator', value: 'pipeline-locator', singleton },
    { title: 'Connecting', value: 'connecting', singleton },
    { title: 'Emergency Information', value: 'emergency-information', singleton },
    { title: 'Help Centre', value: 'help' },
    { title: 'About Us', value: 'about' },
  ],
  [SiteName.SolsticeVictoria]: [
    { title: 'Different Energy', value: 'different-energy' },
    { title: 'Residential', value: 'moving' },
    { title: 'Business', value: 'business' },
    { title: 'Help & Support', value: 'help-and-support' },
    { title: 'About us', value: 'about-us' },
  ],
  [SiteName.GasPipelinesVictoria]: [
    { title: 'User Access Guide', value: 'user-access-guide', singleton },
    { title: 'Sites', value: 'sites', singleton },
    { title: 'Safety', value: 'safety', singleton },
    { title: 'Before You Dig Australia', value: 'before-you-dig', singleton },
    { title: 'About Us', value: 'about', singleton },
  ],
};
