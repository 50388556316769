import clsx from 'clsx';
import { isEmpty } from 'lodash';

import { Box } from '../box';
import { Flex } from '../flex';
import { Icon } from '../icon';
import { Link } from '../link';
import { Text } from '../text';

import { row, textLight, textDark, textHover, chevronRight } from './breadcrumbs.css';

import type { ReactElement, ReactNode } from 'react';

type Path = {
  id?: string;
  path?: string;
  title: string;
  slug?: string;
};

type Props = {
  paths: Path[];
  theme: 'dawn' | 'dusk';
};

const Breadcrumbs = ({ paths, theme }: Props): ReactElement | null => {
  if (isEmpty(paths)) return null;

  const breadcrumbs = [...paths];

  const LinkComponent = ({
    href,
    children,
    className,
    ...props
  }: {
    href: string | undefined;
    children: ReactNode;
    className: string;
  }) => {
    if (href) {
      return (
        <Link className={clsx(textHover, className)} href={href} {...props}>
          {children}
        </Link>
      );
    }
    return (
      <Box className={className} {...props}>
        {children}
      </Box>
    );
  };

  return (
    <Flex as="nav">
      <Box as="ol" className={row}>
        {breadcrumbs.map((path, index) => {
          const isHome = index === 0;
          return (
            <Box as="li" className={clsx(row)} key={path.id || path.title}>
              {!isHome && (
                <Flex className={clsx(chevronRight, theme === 'dawn' ? textDark : textLight)}>
                  <Icon name="chevron_right" />
                </Flex>
              )}
              <LinkComponent className={row} href={path.path}>
                <Text color={theme === 'dawn' ? 'inActive' : 'inActiveContrast'} style={'body2'} weight="bold">
                  {path.title}
                </Text>
              </LinkComponent>
            </Box>
          );
        })}
      </Box>
    </Flex>
  );
};

export { Breadcrumbs };
