'use client';

import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { map } from 'lodash/fp';

import Item from './components/item';
import Trigger from './components/trigger';
import { animation, menu } from './index.css';

import type { Category as CategoryT, NavBar as NavBarT } from '@solstice/types';

type Props = NavBarT.Props & {
  category: CategoryT.Props;
};

export const DropdownMenu = ({ category, pages }: Props) => {
  return (
    <RadixDropdownMenu.Root modal={false}>
      <Trigger title={category.title} />
      <RadixDropdownMenu.Content align="start" alignOffset={-15} asChild className={animation}>
        <ul className={menu}>
          {map(
            (item) => (
              <Item key={item.slug.current} slug={item.slug.current} text={item.title} />
            ),
            pages
          )}
        </ul>
      </RadixDropdownMenu.Content>
    </RadixDropdownMenu.Root>
  );
};
