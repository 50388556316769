'use client';

import { isGPV, isTasGas, siteMap } from '@solstice/constants';
import Link from '@ui/components/next-link';

import { Box } from '../box';
import { Container } from '../container';
import { Flex } from '../flex';
import { GPVLogos, Logos, TasGasLogos } from '../logo';
import { Text } from '../text';

import { DesktopMenu } from './components/desktopMenu';
import { MobileMenu } from './components/mobileMenu';
import {
  block,
  companyInfoContainer,
  contentFlexBox,
  copyRight,
  footerBottom,
  hoverLink,
  otherLinksContainer,
} from './footer.css';

import type { Footer as FooterT } from '@solstice/types';

export const Footer = ({ pages }: FooterT.Props) => {
  return (
    <Box className={block}>
      <Container asChild>
        <footer className={contentFlexBox}>
          <Link href="/">{isTasGas ? <TasGasLogos.Logo /> : isGPV ? <GPVLogos.Logo /> : <Logos.Logo />}</Link>
          <MobileMenu pages={pages} />
          <DesktopMenu pages={pages} />
          <Flex className={otherLinksContainer}>
            <Flex className={footerBottom}>
              <Flex className={otherLinksContainer}>
                <Link className={hoverLink} href={siteMap.termsAndConditions.link}>
                  <Text color="none" style="body3">
                    {siteMap.termsAndConditions.title}
                  </Text>
                </Link>
                <Link className={hoverLink} href={siteMap.privacy.link}>
                  <Text color="none" style="body3">
                    {siteMap.privacy.title}
                  </Text>
                </Link>
                <Link className={hoverLink} href={siteMap.domesticViolence.link}>
                  <Text color="none" style="body3">
                    {siteMap.domesticViolence.title}
                  </Text>
                </Link>
              </Flex>
              <Text className={copyRight} color="contrast" style="body3">
                © Solstice Energy {new Date().getFullYear()}. All Rights Reserved.
              </Text>
            </Flex>
          </Flex>
          <Flex className={companyInfoContainer}>
            <Text color="contrast" style="body3">
              Solstice Energy Retail Pty Ltd
            </Text>
            <Text color="contrast" style="body3">
              5 Kiln Court, Launceston, TAS 7250
            </Text>
            <Text color="contrast" style="body3">
              ABN: 90 110 370 726 T: 1800 750 750
            </Text>
          </Flex>
        </footer>
      </Container>
    </Box>
  );
};
