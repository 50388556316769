import { Box } from '@ui/components/box';
import Image from 'next/image';

import { squiggleContainer, squiggleSubContainer } from './index.css';

export const Squiggle = () => {
  return (
    <Box className={squiggleContainer}>
      <Box className={squiggleSubContainer}>
        <Image alt="" fill src="/squiggle.svg" />
      </Box>
    </Box>
  );
};
