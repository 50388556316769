'use client';

import { TextField } from '@radix-ui/themes';
import { dawnTheme } from '@style-system/theme.css';
import clsx from 'clsx';
import _ from 'lodash';
import { useState, forwardRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Box } from '../../box';
import { Flex } from '../../flex';
import { FieldLabel } from '../../form/field-label';
import { Icon } from '../../icon';
import { Text } from '../../text';

import * as styles from './textInput.css';

import type { TextInput as TextInputT } from '@solstice/types';
import type React from 'react';
import type { ChangeEvent } from 'react';
import type { ChangeHandler } from 'react-hook-form';

const TextInput = forwardRef<HTMLInputElement, TextInputT.Props>(
  (
    {
      defaultValue,
      disabled,
      error,
      errorMessage,
      hint,
      icon,
      suffixIcon,
      suffixOnClick,
      label,
      maxCharacters,
      onChange,
      optional,
      placeholder,
      value,
      theme = 'midnight',
    }: TextInputT.Props,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const [count, setCount] = useState(0);
    const [isInputLimitReached, setIsInputLimitReached] = useState(false);
    const [onFocus, setOnFocus] = useState(false);

    const fieldId = label ? `${_.kebabCase(label)}` : uuidv4();
    const isDawnTheme = theme === 'dawn';

    const handleInputChange = (e: ChangeEvent<HTMLInputElement> | Parameters<ChangeHandler>[0]) => {
      if (onChange) {
        onChange(e);
      }

      if (maxCharacters) {
        const inputValue = e.target.value;
        const truncatedValue = _.truncate(inputValue, {
          length: maxCharacters,
          omission: '',
        });

        setCount(truncatedValue.length);

        inputValue.length > maxCharacters ? setIsInputLimitReached(true) : setIsInputLimitReached(false);

        e.target.value = truncatedValue;
      }
    };

    return (
      <Box className={isDawnTheme && dawnTheme}>
        {label && <FieldLabel fieldId={fieldId} label={label} optional={optional} />}
        <TextField.Root
          className={clsx(
            disabled ? styles.rootDisabled : styles.rootEnabled,
            error && !disabled ? styles.rootError : null,
            onFocus ? styles.rootFocus : styles.rootHover,
            styles.root
          )}
        >
          {icon && (
            <Icon className={clsx(disabled ? styles.iconDisabled : styles.iconEnabled, styles.icon)} name={icon}></Icon>
          )}
          <TextField.Input
            className={clsx(
              disabled ? styles.inputDisabled : null,
              isDawnTheme ? styles.inputLight : null,
              styles.input
            )}
            defaultValue={defaultValue}
            disabled={disabled}
            id={fieldId}
            onBlur={() => setOnFocus(false)}
            onChange={handleInputChange}
            onFocus={() => setOnFocus(true)}
            placeholder={placeholder}
            ref={ref}
            {...(value !== undefined ? { value } : null)}
          />
          {suffixIcon && (
            <Icon
              className={clsx(disabled ? styles.iconDisabled : styles.iconEnabled, styles.icon)}
              name={suffixIcon}
              onClick={suffixOnClick && suffixOnClick}
            ></Icon>
          )}
        </TextField.Root>
        <Flex className={styles.container}>
          {error && !disabled ? (
            <Text color={'error'} style={'body3'}>
              {errorMessage}
            </Text>
          ) : (
            <>
              {isInputLimitReached ? (
                <Text color={'error'} style={'body3'}>
                  You’ve reached the character limit. Please keep your message within {maxCharacters} characters.
                </Text>
              ) : (
                hint && (
                  <Text color={'contrast'} style={'body3'}>
                    {hint}
                  </Text>
                )
              )}
              {maxCharacters && (
                <Text color={'contrast'} style={'body3'}>
                  {`${count}/${maxCharacters}`}
                </Text>
              )}
            </>
          )}
        </Flex>
      </Box>
    );
  }
);

TextInput.displayName = 'TextInput';

export { TextInput };
