import { Flower, Heart, Lightbulb, Smile, CircleArrow, CircleLine } from '@ui/components/brand-icons';
import { clsx } from 'clsx';

import { icon } from './icon.css';

import type { TextVariants } from '../text/index.css';
import type { MaterialSymbol, BrandIcon } from '@solstice/types';
import type { ClassValue } from 'clsx';
interface IconProps {
  className?: ClassValue;
  asButton?: boolean;
  name: MaterialSymbol | '';
  brandName?: BrandIcon | '';
  iconType?: 'brand' | 'icon';
  onClick?: (e: { preventDefault: () => void }) => void;
}

const brandIcons: Record<BrandIcon, React.FC> = {
  Flower,
  Heart,
  Lightbulb,
  Smile,
  CircleArrow,
  CircleLine,
};

export const Icon = ({
  className,
  name = '',
  brandName = '',
  iconType = 'icon',
  color,
  asButton,
  onClick,
}: IconProps & TextVariants) => {
  if (name === 'none') {
    return null;
  }

  if (iconType === 'brand' && brandName) {
    const BrandIconComponent = brandIcons[brandName];
    if (!BrandIconComponent) {
      return null;
    }
    return <BrandIconComponent />;
  }
  if (asButton)
    return (
      <button aria-label={name} className={`${clsx(icon({ color }), className)}`} onClick={onClick} role="img">
        {name}
      </button>
    );
  return (
    <span aria-label={name} className={`${clsx(icon({ color }), className)}`} onClick={onClick} role="img">
      {name}
    </span>
  );
};
