export const projectId =
  process.env.NEXT_PUBLIC_SANITY_PROJECT_ID ??
  process.env.STORYBOOK_SANITY_PROJECT_ID ??
  process.env.SANITY_STUDIO_PROJECT_ID;
export const dataset =
  process.env.NEXT_PUBLIC_SANITY_DATASET ??
  process.env.STORYBOOK_SANITY_DATASET ??
  process.env.SANITY_STUDIO_DATASET ??
  'dev';

export const token = process.env.SANITY_READ_TOKEN;
