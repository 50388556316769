'use client';

import { duskTheme } from '@style-system/theme.css';
import clsx from 'clsx';
import { forwardRef } from 'react';

import { Box } from '../box';
import { CheckBox } from '../checkbox-input';
import { Flex } from '../flex';
import { TextInput } from '../form/text-input';

import { container, displayInput, hiddenInput } from './index.css';

type PromoInputProps = {
  setCheckedInParent: (isChecked: boolean) => void;
  onClearValue: () => void;
  home?: boolean;
  promo?: string;
  isChecked: boolean;
  clearErrors: () => void;
};

const PromoInput = forwardRef<HTMLInputElement, PromoInputProps>(
  (
    { home, promo, isChecked, clearErrors, setCheckedInParent, onClearValue }: PromoInputProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <Flex className={clsx(container, home && duskTheme)}>
        <CheckBox
          checked={isChecked}
          label="I have a promo code"
          onChange={() => {
            setCheckedInParent(!isChecked);
            // isChecked is having its previous state
            if (isChecked) {
              onClearValue();
            }
          }}
        />
        <Box className={isChecked ? displayInput : hiddenInput}>
          <TextInput
            defaultValue={promo ?? ''}
            icon={'shoppingmode'}
            onChange={() => clearErrors()}
            placeholder="Enter code"
            ref={ref}
            suffixIcon="close"
            suffixOnClick={onClearValue}
          />
        </Box>
      </Flex>
    );
  }
);

PromoInput.displayName = 'PromoInput';

export { PromoInput };
