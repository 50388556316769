import { dawnTheme, duskTheme } from '@style-system/theme.css';
import clsx from 'clsx';
import Image from 'next/image';
import { isTasGasOrGPV } from 'packages/constants/src';

import { Box } from '../../box';
import { Button } from '../../button';
import { Container } from '../../container';
import { Flex } from '../../flex';
import { Grid } from '../../grid';
import { HighlightText } from '../../highlight-text';
import { RichText } from '../../rich-text';

import * as styles from './content.css';
import { flourishes } from './flourish';

import type { Content } from '@solstice/types';

export const ContentBlock = ({
  title,
  body,
  flourish,
  cta,
  alignment = 'left',
  theme = 'midnight',
  home,
}: Content.Props) => {
  const imageVariant = alignment === 'center' ? 'center' : 'default';
  const image = !isTasGasOrGPV && flourish && flourish in flourishes && flourishes[flourish];
  const showTitle = !!title;

  return (
    <Box
      background={!home ? 'background' : undefined}
      className={clsx(theme === 'dawn' && dawnTheme, theme === 'dusk' && duskTheme)}
    >
      <Container asChild>
        <Grid columns={12}>
          <Box className={clsx(styles.contentGridRecipe({ alignment, showTitle }))}>
            <Flex
              className={clsx(alignment === 'center' ? styles.center : styles.left)}
              direction="column"
              gap={{ mobile: 'space-d', tablet: 'space-c', desktop: 'space-c' }}
            >
              {showTitle && (
                <Flex
                  alignItems="baseline"
                  className={clsx(image && alignment !== 'center' ? styles.withFlourish : styles.withoutFlourish)}
                  direction={{ mobile: 'column-reverse', tablet: 'row', desktop: 'row' }}
                  gap={{ mobile: 'space-d', tablet: 'none', desktop: 'none' }}
                  position="relative"
                >
                  <HighlightText as="h3" color="contrast" style="h3" value={title} />{' '}
                  {image && (
                    <Image
                      alt={image.alt}
                      className={styles.imageRecipe({ imageVariant })}
                      height={image.height}
                      src={`/${image.src}`}
                      width={image.width}
                    />
                  )}
                </Flex>
              )}

              {body && <RichText color="text/contrast" data={body} />}

              {cta && <Button href={cta.link} label={cta.label} type="primary" />}
            </Flex>
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

ContentBlock.displayName = 'ContentBlock';
