export interface SiteMapEntry {
  title: string;
  link: string;
}

export const siteMap: Record<string, SiteMapEntry> = {
  termsAndConditions: {
    title: 'Terms & Conditions',
    link: '/terms-and-conditions',
  },
  privacy: {
    title: 'Privacy',
    link: '/privacy',
  },
  domesticViolence: {
    title: 'Family Violence',
    link: '/family-violence',
  },
};
