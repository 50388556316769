'use client';

import { LoadingSpinner } from '@ui/components/loading-spinner';
import clsx from 'clsx';
import { useCombobox } from 'downshift';
import { kebabCase } from 'lodash';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { dawnTheme } from '../../../styles/theme.css';
import { Box } from '../../box';
import { Flex } from '../../flex';
import { Icon } from '../../icon';
import { Text } from '../../text';
// Use dropdown css since it's mostly the same as combobox
import * as styles from '../dropdown/dropdown.css';
import { FieldLabel } from '../field-label';

import * as comboBoxStyles from './combo.css';

import type { ComboBox as ComboBoxT } from 'packages/types/src';

const highlightMatch = (text: string, input: string) => {
  // Create a regular expression that matches the sequence of words in the input
  const regex = new RegExp(input.split(' ').join('\\s+'), 'gi');

  const matches = text.match(regex);
  if (!matches) return <span>{text}</span>;

  return text.split(regex).map((part, index, array) => {
    if (index < array.length - 1) {
      return (
        <span key={index}>
          {part}
          <span className={comboBoxStyles.textHightLight}>{matches[index]}</span>
        </span>
      );
    }
    return part;
  });
};

const ComboBox = ({
  items,
  onInputValueChange,
  onSelectedItemChange,
  theme,
  disabled,
  error,
  icon,
  itemIcon,
  placeholder,
  label,
  optional,
  errorMessage,
  hint,
  onClearClicked,
  loading,
}: ComboBoxT.Props) => {
  const { isOpen, openMenu, getMenuProps, getInputProps, getItemProps, inputValue, setInputValue } = useCombobox({
    items,
    onInputValueChange: ({ inputValue }) => {
      if (onInputValueChange) onInputValueChange(inputValue);
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (onSelectedItemChange) onSelectedItemChange(selectedItem);
    },
    itemToString: (item) => {
      if (!item) return '';
      if (item.subLabel && item.label) return `${item.label} ${item.subLabel}`;
      return item.label;
    },
  });

  const onClearClickedHandler = () => {
    setInputValue('');
    if (onClearClicked) onClearClicked();
  };

  const fieldId = label ? `${kebabCase(label)}` : uuidv4();

  const hasText = inputValue.length > 0;
  const hasItems = items.length > 0;

  const isDropdownOpen = !disabled && isOpen && hasItems;
  const placeholderText = !disabled ? placeholder : '';
  const hasSearchIcon = !disabled && icon;
  const searchParams = useSearchParams();

  useEffect(() => {
    const address = searchParams?.get('address');
    if (address) {
      setInputValue(address);
    }
  }, [setInputValue]);

  return (
    <Box
      className={clsx(theme === 'dawn' && dawnTheme, isDropdownOpen && comboBoxStyles.dropdownOffset)}
      style={{ position: 'relative' }}
    >
      {label && <FieldLabel fieldId={fieldId} label={label} optional={optional} />}
      <Flex
        className={clsx(
          disabled ? styles.triggerDisabled : styles.triggerEnabled,
          error ? styles.triggerError : null,
          styles.trigger,
          hasItems && !disabled && comboBoxStyles.containerPointer,
          isDropdownOpen && comboBoxStyles.containerOpen
        )}
        id={fieldId}
        onClick={() => {
          openMenu();
        }}
      >
        {hasSearchIcon && !loading && (
          <Icon className={disabled ? styles.iconDisabled : styles.iconEnabled} name={icon} />
        )}
        {loading && !disabled && <LoadingSpinner className={comboBoxStyles.spinner} size="small" theme={'dawn'} />}
        <input
          {...getInputProps({ placeholder: placeholderText })}
          className={clsx(styles.value, comboBoxStyles.inputBox, disabled && comboBoxStyles.disabledInputBox)}
          disabled={disabled}
        />
        {hasText && (
          <Icon
            className={clsx(styles.iconEnabled, comboBoxStyles.closeIcon)}
            name={'close'}
            onClick={() => {
              onClearClickedHandler();
            }}
          />
        )}
      </Flex>
      <ul {...getMenuProps()} className={clsx(isDropdownOpen && comboBoxStyles.dropdown)}>
        {isDropdownOpen &&
          items.map((item, index) => (
            <li
              key={`${fieldId} ${index}`}
              {...getItemProps({ item, index })}
              className={clsx(comboBoxStyles.dropdownItem, styles.item)}
            >
              {itemIcon && <Icon name={itemIcon} />}
              <Flex className={comboBoxStyles.addressBar}>
                <Text className={comboBoxStyles.label} style={'body2'}>
                  {highlightMatch(item.label, inputValue)}
                </Text>
                <Text className={comboBoxStyles.subLabel} style={'body2'}>
                  {highlightMatch(item.subLabel, inputValue)}
                </Text>
              </Flex>
            </li>
          ))}
      </ul>
      <Flex>
        {error
          ? errorMessage && (
              <Text color={'error'} style={'body3'}>
                {errorMessage}
              </Text>
            )
          : hint && (
              <Text color={'contrast'} style={'body3'}>
                {hint}
              </Text>
            )}
      </Flex>
    </Box>
  );
};

export { ComboBox };
