import { getImage } from '@solstice/lib';
import { midnightTheme, dawnTheme, duskTheme } from '@style-system/theme.css';
import { Box } from '@ui/components/box';
import { Button } from '@ui/components/button';
import { Container } from '@ui/components/container';
import { Flex } from '@ui/components/flex';
import { Heading } from '@ui/components/heading';
import { Text } from '@ui/components/text';
import clsx from 'clsx';
import Image from 'next/image';

import { container, contentBoxRecipe, highlightBox, imageContainer, contentStyle } from './callToAction.css';

import type { CallToAction } from '@solstice/types';

export const CallToActionBlock = ({ title, content, variant, cta, image, home }: CallToAction.Props) => {
  return (
    <Flex
      background={!home ? 'background' : undefined}
      className={clsx(
        container,
        variant === 'midnight' && dawnTheme,
        variant === 'dawn' && midnightTheme,
        variant === 'dusk' && dawnTheme
      )}
    >
      <Container>
        <Flex
          background="background"
          className={clsx(
            highlightBox,
            midnightTheme,
            variant === 'dawn' && dawnTheme,
            variant === 'dusk' && duskTheme
          )}
        >
          <Flex
            className={clsx(
              contentBoxRecipe({ image: image ? 'withImage' : 'noImage' }),
              midnightTheme,
              variant === 'dawn' && dawnTheme,
              variant === 'dusk' && duskTheme
            )}
            color="text/contrast"
          >
            {title && (
              <Heading as="h2" color="contrast" style="h4">
                {title}
              </Heading>
            )}
            {content && (
              <Text className={contentStyle} color="contrast" style="body2">
                {content}
              </Text>
            )}
            {cta && <Button href={cta.link} label={cta.label} type="primary" />}
          </Flex>
          {image && (
            <Box className={imageContainer}>
              <Image alt={image.alt} fill src={getImage(image).url()} style={{ objectFit: 'cover', height: '100%' }} />
            </Box>
          )}
        </Flex>
      </Container>
    </Flex>
  );
};
