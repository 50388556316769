import { Flex } from '@ui/components/flex';
import Link from '@ui/components/next-link';
import { Text } from '@ui/components/text';
import { filter, map } from 'lodash/fp';
import { usePathname } from 'next/navigation';

import { itemsContainer, item, title } from './mobileSubMenu.css';

import type { Page as PageT, NavBar as NavBarT, Category as CategoryT } from '@solstice/types';

type Props = NavBarT.Props & {
  activeCategory: CategoryT.Props | undefined | null;
  setMobileNavOpen: (val: boolean) => void;
};

export const MobileSubMenu = ({ activeCategory, pages, setMobileNavOpen }: Props) => {
  const pathname = usePathname();
  if (!activeCategory) {
    return null;
  }
  const subMenuItems = filter((page: PageT.Props) => page.category === activeCategory.value, pages);
  return (
    <Flex className={itemsContainer}>
      <Text className={title} color="none" style="preHeading">
        {activeCategory.title}
      </Text>
      {map(
        (link) => (
          <Link
            className={item}
            href={link.slug.current}
            key={link.slug.current}
            onClick={() => {
              if (`/${link.slug.current}` === pathname) {
                setMobileNavOpen(false);
              }
            }}
          >
            <Text color="none" style="body1">
              {link.title}
            </Text>
          </Link>
        ),
        subMenuItems
      )}
    </Flex>
  );
};
