import * as RadixDialog from '@radix-ui/react-dialog';
import { Box } from '@ui/components/box';
import { Button } from '@ui/components/button';
import { Flex } from '@ui/components/flex';
import { Icon } from '@ui/components/icon';
import Link from '@ui/components/next-link';
import { Text } from '@ui/components/text';
import { map } from 'lodash/fp';
import { categories } from 'packages/constants/src';
import { useState } from 'react';

import { GeographySelector } from '../geography-selector';
import { MobileNavTrigger } from '../mobileNavTrigger';
import { MobileSubMenu } from '../mobileSubMenu';

import {
  actionButton,
  actionButtonsContainer,
  animation,
  backIcon,
  closeIcon,
  closeMenu,
  divider,
  footerLink,
  footerLinksContainer,
  itemsContainer,
  icon,
  mobileNavMenu,
  subItemsContainer,
  triggerslist,
} from './mobileNavMenu.css';

import type { NavBar as NavBarT, Category as CategoryT } from '@solstice/types';
import type { SiteName } from 'packages/constants/src';

type Props = NavBarT.Props & {
  setMobileNavOpen: (val: boolean) => void;
};

export const MobileNavMenu = ({ pages, setMobileNavOpen }: Props) => {
  const [activeCategory, setActiveCategory] = useState<CategoryT.Props | null>();
  const [subNavOpen, setSubNavOpen] = useState(false);

  return (
    <Flex className={mobileNavMenu}>
      <RadixDialog.Root modal={false} onOpenChange={(open: boolean) => setSubNavOpen(open)} open={subNavOpen}>
        <Flex className={closeMenu}>
          <div>
            {subNavOpen && (
              <button className={backIcon} onClick={() => setSubNavOpen(false)}>
                <Icon className={icon} name="arrow_left_alt" />
              </button>
            )}
          </div>

          <button className={closeIcon} onClick={() => setMobileNavOpen(false)}>
            <Icon className={icon} name="close" />
          </button>
        </Flex>
        <Flex className={itemsContainer}>
          <Flex className={triggerslist}>
            {map(
              (category) => (
                <MobileNavTrigger
                  category={category}
                  key={category.value}
                  setActiveCategory={setActiveCategory}
                  setSubNavOpen={setSubNavOpen}
                />
              ),
              categories[(process.env.NEXT_PUBLIC_SITE ?? 'Solstice') as SiteName]
            )}
          </Flex>
          <RadixDialog.Content asChild className={animation}>
            <Flex className={subItemsContainer}>
              <MobileSubMenu activeCategory={activeCategory} pages={pages} setMobileNavOpen={setMobileNavOpen} />
            </Flex>
          </RadixDialog.Content>
          <Box>
            {/* TODO: Hook up these links when pages are built */}
            <Flex className={actionButtonsContainer}>
              {/* <Button className={actionButton} label="Login" showIcon={false} type="secondary" /> */}
              {process.env.NEXT_PUBLIC_SITE === 'Solstice' && (
                <Button
                  className={actionButton}
                  href="/apply-online"
                  label="Join now"
                  showIcon={false}
                  type="primary"
                />
              )}
            </Flex>
            <Flex className={footerLinksContainer}>
              <Link href="/network-alerts">
                <Text className={footerLink} color="none" style="body2">
                  Gas Network Alerts
                </Text>
              </Link>
              <Box className={divider} />
              <Link href="/contact-us">
                <Text className={footerLink} color="none" style="body2">
                  Contact us
                </Text>
              </Link>
            </Flex>
            <GeographySelector />
          </Box>
        </Flex>
      </RadixDialog.Root>
    </Flex>
  );
};
