const postForm = async (url: string, data: unknown) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: data as BodyInit,
    });
    if (!response.ok) {
      const res = await response.json();
      throw new Error(res.message);
    }
    return { success: true };
  } catch (error) {
    if (error instanceof Error) return { success: false, message: error.message };
    return { success: false, message: 'Mail not sent due to Unknown error, please contact site admin' };
  }
};

export { postForm };
