'use client';

import { dawnTheme, duskTheme } from '@style-system/theme.css';
import { Container } from '@ui/components/container';
import { LoadingSpinner } from '@ui/components/loading-spinner';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

import { Box } from '../../box';
import { Button } from '../../button';
import { Flex } from '../../flex';
import { Grid } from '../../grid';
import { HighlightText } from '../../highlight-text';
import { Icon } from '../../icon';

import { Item } from './item';
import * as styles from './quote.css';

import type { Quote } from 'packages/types/src';

export const QuoteBlock = ({ heading, quoteItems, theme = 'midnight' }: Quote.Props) => {
  const [currentSlide, setCurrentSlide] = useState(1);

  const activeSlideRef = useRef<HTMLDivElement | null>(null);
  const itemsLength = quoteItems.length - 1;
  const showLoadingSpinner = quoteItems.length === 0 || !quoteItems;

  useEffect(() => {
    if (activeSlideRef.current !== null) {
      activeSlideRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }, [activeSlideRef.current]);

  const moreThanOneItem = quoteItems.length > 1;

  const enabledPrev = moreThanOneItem && currentSlide > 0;
  const enabledNext = moreThanOneItem && currentSlide < itemsLength;

  const goNext = () => {
    if (currentSlide >= 0 && currentSlide < itemsLength) {
      setCurrentSlide((currentSlide) => currentSlide + 1);
    }
  };

  const goPrev = () => {
    if (currentSlide > 0 && currentSlide <= itemsLength) {
      setCurrentSlide((currentSlide) => currentSlide - 1);
    }
  };

  const goTo = (i: number) => {
    setCurrentSlide(i);
  };

  return (
    <Box
      background="background"
      className={clsx(theme === 'dawn' && dawnTheme, theme === 'dusk' && duskTheme)}
      paddingY="space-j"
    >
      <Container>
        {showLoadingSpinner ? (
          <Flex justifyContent="center" marginBottom="space-g">
            <LoadingSpinner size="large" theme={theme} />
          </Flex>
        ) : (
          <Grid alignItems="center" columns={12} position="relative" textAlign="center">
            <Button
              ariaLabel="previous"
              className={styles.prev}
              disabled={!enabledPrev}
              icon={<Icon name="arrow_left_alt" />}
              onClick={() => goPrev()}
              type="primary"
            />
            <Box className={styles.wrapper}>
              <Flex direction="column" gap="space-e">
                <HighlightText as="h2" color="contrast" style="h3" value={heading} />

                <Flex as="ul" className={styles.slider}>
                  {quoteItems?.length > 1 &&
                    quoteItems.map(({ body, caption }, i) => {
                      const active = i === currentSlide;

                      return (
                        <Item
                          body={body}
                          caption={caption}
                          className={styles.item}
                          key={`${caption}-${i}`}
                          ref={active ? activeSlideRef : null}
                        />
                      );
                    })}
                </Flex>

                <Box as="ul" className={styles.sliderNav}>
                  {quoteItems?.length > 1 &&
                    quoteItems.map((_, i) => (
                      <Box as="li" key={`slide-${i}`}>
                        <button
                          aria-label={`slide-number-${i}`}
                          className={styles.dot({ active: i === currentSlide })}
                          onClick={() => goTo(i)}
                        />
                      </Box>
                    ))}
                </Box>
              </Flex>
            </Box>
            <Button
              ariaLabel="next"
              className={styles.next}
              disabled={!enabledNext}
              onClick={() => goNext()}
              type="primary"
            />
          </Grid>
        )}
      </Container>
    </Box>
  );
};

QuoteBlock.displayName = 'QuoteBlock';
