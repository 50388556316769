import { Slot } from '@radix-ui/react-slot';

import { Box } from '../box';
import { Flex } from '../flex';

import { container, widthController } from './index.css';

import type { PropsWithChildren } from 'react';

export const Container = ({ asChild, children }: PropsWithChildren<{ asChild?: boolean }>) => {
  const Comp = asChild ? Slot : Box;
  return (
    <Flex align="center" className={container} direction="column">
      <Comp className={widthController}>{children}</Comp>
    </Flex>
  );
};
