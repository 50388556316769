'use client';
import clsx from 'clsx';
import { has } from 'lodash';
import { useState } from 'react';

import { useIsMaxWidth } from '../../hooks/use-width';
import { Box } from '../box';
import { Flex } from '../flex';
import { Icon } from '../icon';
import { Link } from '../link';
import { Text } from '../text';

import { container, widthController, underline, nowrap, limitHeight } from './index.css';

import type { EmergencyBanner } from '@solstice/types';

export const EmergencyBannerBlock = ({ banner, CTA, icon, visibility }: EmergencyBanner.Props) => {
  const [bannerVisibility, setBannerVisibility] = useState(visibility);
  const isMobile = useIsMaxWidth('tablet');

  return (
    bannerVisibility && (
      <Flex align="center" className={container} direction="column">
        <Box className={widthController}>
          <Flex direction="row" justifyContent="space-between" paddingY="space-b">
            <Flex alignItems={{ desktop: 'center' }} direction={isMobile ? 'column' : 'row'} gap="space-b">
              <Flex alignItems="center" gap="space-a">
                <Icon name={icon ? icon : 'info'} />
                <Text className={limitHeight} style={'body3'}>
                  {banner}
                </Text>
              </Flex>
              <Link href={CTA.link} style={{ display: 'flex', alignItems: 'center' }}>
                <Text className={clsx([nowrap, underline])} style={'body3'} weight={'bold'}>
                  {has(CTA, 'CTA.label') ? CTA.label : 'See details'}
                </Text>
                <Icon name={'arrow_right_alt'} />
              </Link>
            </Flex>
            <Flex
              alignItems={{ desktop: 'center', tablet: 'center', mobile: 'flex-start' }}
              justifySelf="flex-end"
              marginLeft="space-a"
              style={{ cursor: 'pointer' }}
            >
              <Icon
                name="close"
                onClick={() => {
                  setBannerVisibility(false);
                }}
              />
            </Flex>
          </Flex>
        </Box>
      </Flex>
    )
  );
};
