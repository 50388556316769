import { getImage } from '@solstice/lib';
import Image from 'next/image';

import { Box } from '../box';
import { Button } from '../button';
import { Flex } from '../flex';
import { Heading } from '../heading';
import { Text } from '../text';

import * as styles from './index.css';

import type { TeamMember } from '@solstice/types';

export const TeamMemberComponent = ({ image, name, role, bio, cta }: TeamMember.Props) => {
  return (
    <Flex className={styles.cardStyles}>
      <Box className={styles.image} position="relative">
        <Image alt={image.alt} className={styles.imageContent} fill src={getImage(image)?.url?.()} />
      </Box>
      <Flex direction="column" gap="space-c">
        <Flex direction="column" gap="space-a">
          <Heading as="h3" style="h4">
            {name}
          </Heading>

          <Text as="h6" style="preHeading">
            {role}
          </Text>
        </Flex>
        <Text style="body2" textAlign="center">
          {bio}
        </Text>

        {/* cta */}
        {cta && (
          <Flex justifySelf="flex-end">
            <Button color="default" href={cta.link} label={cta.label} type="tertiary" />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
