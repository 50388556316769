import { forwardRef } from 'react';

import { Flex } from '../../../flex';
import { Heading } from '../../../heading';
import { Text } from '../../../text';

import type { Quote } from '@solstice/types';
import type { ClassValue } from 'clsx';

type QuoteItemsProps = Quote.QuoteProps & {
  className?: ClassValue;
};

export const Item = forwardRef<HTMLElement, QuoteItemsProps>(({ body, caption, className }, ref) => (
  <Flex aria-label={body} as="li" className={className} ref={ref}>
    <Heading as="h3" color="contrast" style="h5">
      {body}
    </Heading>
    <Text color="contrast" style="body2">
      {caption}
    </Text>
  </Flex>
));

Item.displayName = 'SliderItem';
