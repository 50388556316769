import { Controller } from 'react-hook-form';

import { TextInput } from '../../../form/text-input';

import type { FormField as FormFieldT, Validation as ValidationT } from 'packages/types/src';
import type { Control } from 'react-hook-form';

export interface Props {
  control: Control;
  field: FormFieldT.Props;
  theme: 'dawn' | 'midnight' | 'dusk' | 'tasGas';
  name: string;
  type: 'text' | 'number' | 'email' | 'phone' | 'date';
  generateRequiredRule: ValidationT.GenerateRequiredRule;
}

const generateValidationRules = (
  type: Props['type'],
  isMandatory = false,
  generateRequiredRule: ValidationT.GenerateRequiredRule
) => {
  const basicValidationRules: ValidationT.BasicValidationRules = {
    ...generateRequiredRule(isMandatory),
  };
  switch (type) {
    case 'email':
      basicValidationRules.pattern = { value: /\S+@\S+\.\S+/, message: 'Please enter a valid email address' };
      break;
    case 'number':
      basicValidationRules.pattern = { value: /\d/, message: 'Please enter a number' };
      break;
    case 'date':
      basicValidationRules.pattern = {
        value: /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
        message: 'Please enter a valid date',
      };
      break;
    case 'phone':
      basicValidationRules.pattern = {
        message: 'Please enter a valid phone number',
        value: /^((\+61\s?)?(\((0|02|03|04|07|08)\))?)?\s?\d{1,4}\s?\d{1,4}\s?\d{0,4}$/,
      };
      break;
    default:
      break;
  }
  return basicValidationRules;
};

const generatePlaceholders = (type: Props['type'], placeholderText = '') => {
  switch (type) {
    case 'email':
      return 'example@domain.com';
    case 'phone':
      return '+61';
    case 'date':
      return 'DD/MM/YYYY';

    default:
      return placeholderText;
  }
};

export const FormTextInput = ({ control, field, theme, type, name, generateRequiredRule }: Props) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <TextInput
        error={!!error}
        errorMessage={error?.message}
        label={field.label}
        onChange={onChange}
        optional={!field.isMandatory}
        placeholder={generatePlaceholders(type, field.placeholderText)}
        theme={theme}
        value={value || ''}
      />
    )}
    rules={generateValidationRules(type, field.isMandatory, generateRequiredRule)}
  />
);
