import { getImage } from '@solstice/lib';
import clsx from 'clsx';
import Image from 'next/image';

import { dawnTheme, duskTheme } from '../../../styles/theme.css';
import { Box } from '../../box';
import { Button } from '../../button';
import { Container } from '../../container';
import { Flex } from '../../flex';
import { Grid } from '../../grid';
import { HighlightText } from '../../highlight-text';
import { List } from '../../list';
import { Text } from '../../text';

import * as styles from './contentImage.css';

import type { ContentImage } from '@solstice/types';

export const ContentImageBlock = ({
  title,
  image,
  content,
  list,
  cta,
  variant,
  align = 'left',
  home,
}: ContentImage.Props) => {
  return (
    <Box
      background={!home ? 'background' : undefined}
      className={clsx(styles.container, variant === 'dawn' && dawnTheme, variant === 'dusk' && duskTheme)}
      paddingY="space-j"
    >
      <Container asChild>
        <Grid columns={{ mobile: 1, tablet: 1, desktop: 2 }} gap="space-g">
          <Flex align="flex-start" className={styles.content({ align })} direction="column" gap={'space-c'}>
            {title && <HighlightText as="h2" color="contrast" style="h3" value={title} />}
            {content && (
              <Text color="contrast" style="body2">
                {content}
              </Text>
            )}
            {list && <List items={list} theme={variant} type="circle" />}
            {cta && <Button href={cta.link} label={cta.label} type="primary" />}
          </Flex>
          {image && (
            <Box className={styles.imageWrapper({ align })}>
              <Box className={styles.image} key="image-block" overflow="hidden" position="relative">
                <Image alt={image.alt} fill src={getImage(image).url()} style={{ objectFit: 'cover' }} />
              </Box>
            </Box>
          )}
        </Grid>
      </Container>
    </Box>
  );
};
