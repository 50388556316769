'use client';
import clsx from 'clsx';
import { useRef } from 'react';

import { useIsMaxWidth } from '../../../hooks/use-width';
import { dawnTheme, duskTheme, midnightTheme } from '../../../styles/theme.css';
import { Box } from '../../box';
import { Flex } from '../../flex';
import { Heading } from '../../heading';
import { Icon } from '../../icon';
import { LoadingSpinner } from '../../loading-spinner';
import { Text } from '../../text';

import {
  boldFont,
  browseLink,
  uploadBox,
  textBox,
  loadingBox,
  uploadedFile,
  greyText,
  cursorPointer,
  borderError,
} from './index.css';

import type { Theme } from '@solstice/types';

type ModifiedTheme = Exclude<Theme.Props, 'tasGas'>;

type Props = {
  label: string;
  name: string;
  onDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  theme: ModifiedTheme;
  isUploading: boolean;
  files: File[];
  error: 'fileTooBig' | 'unsupportedFile' | 'mandatory' | 'fileLimitExceed' | '';
  onFileInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFileRemove: (index: number) => void;
};
type UploadedFileProps = {
  name: string;
  size: number;
  onDelete: (e: { preventDefault: () => void }) => void;
};
type ErrorBoxProps = {
  type: 'fileTooBig' | 'unsupportedFile' | 'mandatory' | 'fileLimitExceed' | '';
};

const UploadedFileBlock = ({ name, size, onDelete }: UploadedFileProps) => {
  const isMobile = useIsMaxWidth('tablet');
  const CHARACTER_LIMIT = isMobile ? 10 : 15;

  const getName = () => {
    const fileName = name.slice(0, name.lastIndexOf('.'));
    const extension = name.slice(name.lastIndexOf('.'));
    let outputName = name;

    if (fileName.length > CHARACTER_LIMIT) {
      outputName = `${fileName.slice(0, CHARACTER_LIMIT)}...${fileName.slice(
        fileName.length - 1,
        fileName.length
      )}${extension}`;
    }

    return outputName;
  };
  return (
    <Flex className={uploadedFile} justifyContent="space-between" paddingX="space-b" paddingY="space-d">
      <Flex gap="space-b">
        <Icon color="default" name="article" />
        <Text style="body2">{getName()}</Text>
        <Text className={greyText} style="body2">
          {(size / 1024).toFixed(2)} kb
        </Text>
      </Flex>
      <Icon asButton className={cursorPointer} color="default" name="delete" onClick={onDelete} />
    </Flex>
  );
};
const ErrorBlock = ({ type }: ErrorBoxProps) => {
  if (type === 'fileLimitExceed')
    return (
      <Flex gap="space-b">
        <Icon color="error" name="warning" />
        <Flex flexDirection="column">
          <Text color="error" style="body1" weight="bold">
            You can only upload a max of 4 files.
          </Text>
        </Flex>
      </Flex>
    );
  if (type === 'mandatory')
    return (
      <Text color="error" marginBottom="space-a" style="body2">
        Please upload the required documents
      </Text>
    );
  return (
    <Flex gap="space-b">
      <Icon color="error" name="warning" />
      <Flex flexDirection="column">
        <Text color="error" style="body1" weight="bold">
          {type === 'fileTooBig' ? 'Oops, that file size is too big.' : `We can't accept that file type.`}
        </Text>
        <Text color="contrast" marginBottom="space-a" style="body2">
          {type === 'fileTooBig' ? (
            <>File size must not exceed {<span className={boldFont}>10MB</span>}. Please upload another file</>
          ) : (
            <>
              You can upload {<span className={boldFont}>pdf, png, jpeg, jpg,</span>} and
              {<span className={boldFont}> heic</span>} files. Please upload another file
            </>
          )}
        </Text>
      </Flex>
    </Flex>
  );
};
export const FileInputLayout = ({
  label,
  name,
  onDrop,
  theme,
  isUploading,
  files,
  error,
  onFileInputChange,
  onFileRemove,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const onBrowseClick = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    inputRef.current?.click();
  };
  const onDragOver = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };

  return (
    <Flex
      className={clsx(
        { [dawnTheme]: theme === 'dawn' },
        { [midnightTheme]: theme === 'midnight' },
        { [duskTheme]: theme === 'dusk' }
      )}
      direction="column"
      gap="space-d"
      onDragOver={onDragOver}
      onDrop={onDrop}
    >
      <Heading aria-label={label} color="contrast" style="h5">
        {label}
      </Heading>
      <Box className={clsx([uploadBox({ theme }), error === 'mandatory' && borderError])}>
        {!isUploading ? (
          <Flex className={textBox}>
            <Icon color="contrast" name="upload" />
            <input
              accept=".pdf, .jpg, .jpeg, .png, .heic"
              multiple
              name={name}
              onChange={onFileInputChange}
              ref={inputRef}
              style={{
                display: 'none',
              }}
              type="file"
            />
            <Text color="contrast" style="body1" weight="bold">
              Drag and drop, or{' '}
              <button className={browseLink} onClick={onBrowseClick}>
                browse your files
              </button>
            </Text>
            <Flex alignItems="center" direction="column" gap="space-a">
              <Text color="contrast" style="body2">
                File size must not exceed <span className={boldFont}>10MB</span>
              </Text>
              <Text color="contrast" style="body2">
                You can upload <span className={boldFont}>pdf, png, jpeg, jpg, </span>and{' '}
                <span className={boldFont}>heic</span> files.
              </Text>
              <Text color="contrast" style="body2">
                You can upload a maximum of 4 files
              </Text>
            </Flex>
          </Flex>
        ) : (
          <Flex className={loadingBox}>
            <LoadingSpinner theme={theme} />
            <Text color="contrast" style="body1" weight="bold">
              Uploading file...
            </Text>
            <Text color="contrast" style="body2">
              Please wait a moment...
            </Text>
          </Flex>
        )}
      </Box>
      {error !== '' && error && <ErrorBlock type={error} />}
      {!!files &&
        files?.map((file, index) => (
          <UploadedFileBlock
            key={index}
            name={file.name}
            onDelete={(e: { preventDefault: () => void }) => {
              e.preventDefault();
              onFileRemove(index);
            }}
            size={file.size}
          />
        ))}
    </Flex>
  );
};
