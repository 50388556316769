import { PortableText } from '@portabletext/react';
import clsx from 'clsx';
import { values } from 'lodash';

import { Flex } from '../flex';
import { Heading } from '../heading';

import { highlight } from './highlight.css';

import type { HeadingVariants } from '../heading/heading.css';
import type { PortableTextReactComponents } from '@portabletext/react';
import type { RichText as RichTextT } from '@solstice/types';
import type { ClassValue } from 'clsx';

const componentMap = (highlightColor?: string): Partial<PortableTextReactComponents> => ({
  block: {
    normal: ({ children }) => <span>{children}</span>,
  },
  marks: {
    highlight: ({ children }) => (
      <span className={clsx(!highlightColor && highlight)} style={highlightColor ? { color: highlightColor } : {}}>
        {children}
      </span>
    ),
  },
});

type HighlightProps = HeadingVariants & {
  value: RichTextT.Props;
  as?: React.ElementType;
  className?: ClassValue;
  children?: React.ReactNode;
  highlightColor?: string;
};
export const HighlightText = ({
  value,
  style,
  color,
  as,
  children,
  highlightColor,
  className,
  ...headingProps
}: HighlightProps) => {
  return (
    <Heading as={as} className={className} color={color} style={style} {...headingProps}>
      <Flex columnGap={{ mobile: 'space-b', tablet: 'space-b', desktop: 'space-b' }} wrap="wrap">
        <PortableText components={componentMap(highlightColor)} value={values(value)} />
      </Flex>
      {children}
    </Heading>
  );
};
