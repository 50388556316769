import { getImage } from '@solstice/lib';
import Image from 'next/image';
import { isTasGasOrGPV } from 'packages/constants/src';

import { Box } from '../box';
import { Button } from '../button';
import { Flex } from '../flex';
import { Heading } from '../heading';
import { Text } from '../text';

import * as styles from './card.css';

import type { Card as CardT } from '@solstice/types';

export const Card = ({ title, body, image, icon, cta }: CardT.Props) => (
  <Flex
    align="flex-start"
    backgroundColor="card/background"
    borderBottomRightRadius={isTasGasOrGPV ? '5' : '6'}
    direction="column"
    gap="space-b"
    marginTop={icon && 'space-f'}
    padding="space-e"
    paddingTop={icon ? 'space-f' : 'space-e'}
    position="relative"
    radius="5"
  >
    {/* image OR icon */}
    {image && (
      <Box className={styles.image} position="relative">
        <Image alt={image.alt} className={styles.imageContent} fill src={getImage(image)?.url?.()} />
      </Box>
    )}

    {!image && icon && (
      <Box className={styles.icon} position="absolute">
        <Image alt={icon.alt} className={styles.iconContent} fill src={getImage(icon)?.url?.()} />
      </Box>
    )}

    {/* content */}
    <Flex direction="column" gap="space-b">
      <Heading color="default" style="h5">
        {title}
      </Heading>
      <Text color="default" style="body2">
        {body}
      </Text>
    </Flex>

    <Flex grow="1" />

    {/* cta */}
    {cta && (
      <Flex justifySelf="flex-end">
        <Button color="default" href={cta.link} label={cta.label} type="tertiary" />
      </Flex>
    )}
  </Flex>
);
