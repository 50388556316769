'use client';
/* eslint-disable @typescript-eslint/no-explicit-any */

import { acceptedFiles } from '@solstice/constants';
import { FormField } from '@ui/components/form-field';
import clsx from 'clsx';
import { map, isArray, every } from 'lodash';
import { SEND_PROPERTIES } from 'packages/constants/src';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { dawnTheme, duskTheme, midnightTheme } from '../../../styles/theme.css';
import { Box } from '../../box';
import { Button } from '../../button';
import { Container } from '../../container';
import { Flex } from '../../flex';
import { Grid } from '../../grid';
import { Heading } from '../../heading';
import { Text } from '../../text';

import * as styles from './formBlock.css';
import { postForm } from './helper';

import type { FormBlock as FormBlockT } from '@solstice/types';
const { SEND_FROM, SEND_TO, SEND_TIME, SEND_SUBJECT } = SEND_PROPERTIES;

const mapKeysToLabels = (data: FormBlockT.Props['form']): { [key: string]: string } => {
  return data.fieldsets.reduce(
    (outerMap, fieldset) => {
      return fieldset.fields.reduce((innerMap, field) => {
        const fullLabel = `${fieldset.heading}: ${field.label}`;
        innerMap[`${fieldset._key}:${field._key}`] = fullLabel;
        return innerMap;
      }, outerMap);
    },
    {} as { [key: string]: string }
  );
};

export const FormBlock = ({ form, theme }: FormBlockT.Props) => {
  const [sent, setSent] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();
  const keyLabelMap = useMemo(() => mapKeysToLabels(form), [form]);

  const onSubmit = async (data: Record<string, any>) => {
    const mappedData: {
      [key: string]: {
        label: string;
        value: string;
      };
    } = {};

    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        for (const labelKey in keyLabelMap) {
          if (key.startsWith(labelKey)) {
            const baseKey = labelKey;
            const label = keyLabelMap[baseKey];
            const value = data[key];

            // Create a unique identifier if it is a checkbox item
            const checkboxItem = key.substring(baseKey.length + 1);

            // Create a unique key for the mappedData object
            const mappedKey = checkboxItem.length > 0 ? `${baseKey} ${checkboxItem}` : baseKey;

            // Create a unique label for the mappedData object
            const uniqueLabel = checkboxItem.length > 0 ? `${label}: ${checkboxItem}` : label;

            // Add the entry to the mappedData object
            mappedData[mappedKey] = {
              label: uniqueLabel,
              value: checkboxItem.length > 0 ? value : data[key],
            };
          }
        }
      }
    }

    // Unmapped data to it's original form
    const formData = new FormData();
    formData.append(SEND_FROM, form.sendingEmail);
    formData.append(SEND_TO, form.targetEmail);
    formData.append(SEND_TIME, new Date().toLocaleString('en-US', { timeZone: 'Australia/Sydney' }));
    formData.append(SEND_SUBJECT, form.title);

    map(mappedData, (inputData) => {
      if (isArray(inputData.value) && every(inputData.value, (files: Blob) => acceptedFiles.includes(files.type))) {
        map(inputData.value, (file: Blob) => {
          formData.append('attachments', file);
        });
        return;
      }
      formData.append(inputData.label, inputData.value);
    });

    const result = await postForm('/api/email', formData);
    if (!result.success) {
      alert(result.message);
      return;
    } else {
      setSent(true);
    }
  };

  if (sent) {
    return (
      <Box
        background="background"
        className={clsx(
          { [dawnTheme]: theme === 'dawn' },
          { [midnightTheme]: theme === 'midnight' },
          { [duskTheme]: theme === 'dusk' }
        )}
        paddingY="space-j"
      >
        <Container>
          <Heading color="contrast">Thank you</Heading>
          <Text color="contrast">One of our team will be in touch as soon as possible.</Text>
        </Container>
      </Box>
    );
  }

  return (
    <Box
      background="background"
      className={clsx(
        { [dawnTheme]: theme === 'dawn' },
        { [midnightTheme]: theme === 'midnight' },
        { [duskTheme]: theme === 'dusk' }
      )}
      paddingY="space-j"
    >
      <Container asChild>
        <Grid columns={12}>
          <Flex className={styles.content}>
            <form className={clsx(styles.form)} onSubmit={handleSubmit(onSubmit)}>
              {form?.fieldsets?.map((fieldset) => (
                <Box key={fieldset?._key} marginBottom="space-h">
                  <Heading aria-label={fieldset?.heading} as="h2" color="contrast" marginBottom="space-d" style="h5">
                    {fieldset?.heading}
                  </Heading>
                  <Flex flexDirection="column" rowGap="space-d">
                    {fieldset.fields.map((field) => (
                      <FormField
                        control={control}
                        field={field}
                        fieldsetKey={fieldset._key}
                        key={field._key}
                        theme={theme}
                      />
                    ))}
                  </Flex>
                </Box>
              ))}

              <Button
                disabled={isSubmitting}
                label={isSubmitting ? `Submitting ...` : form?.submitButtonText}
                showIcon={false}
                type="primary"
              />
            </form>
          </Flex>
        </Grid>
      </Container>
    </Box>
  );
};
