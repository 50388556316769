import { Flex } from '@ui/components/flex';
import { Icon } from '@ui/components/icon';
import { Text } from '@ui/components/text';

import { mobileNavTrigger } from './mobileNavTrigger.css';

import type { Category as CategoryT } from '@solstice/types';

type Props = {
  category: CategoryT.Props;
  setActiveCategory: (val: CategoryT.Props) => void;
  setSubNavOpen: (val: boolean) => void;
};

export const MobileNavTrigger = ({ category, setActiveCategory, setSubNavOpen }: Props) => {
  return (
    <button
      className={mobileNavTrigger}
      onClick={() => {
        setActiveCategory(category);
        setSubNavOpen(true);
      }}
    >
      <Flex align="center" justifyContent="space-between">
        <Text color="none" style="body1">
          {category.title}
        </Text>
        <Icon name="chevron_right" />
      </Flex>
    </button>
  );
};
