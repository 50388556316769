import { gridSprinkles } from '@style-system/atoms/sprinkles.css';
import clsx from 'clsx';
import * as React from 'react';

import type { GridSprinkles } from '@style-system/atoms/sprinkles.css';
import type { ClassValue } from 'clsx';

type HTMLProperties<T = HTMLElement> = Omit<
  React.AllHTMLAttributes<T>,
  'as' | 'className' | 'color' | 'height' | 'width' | 'color' | 'cursor'
>;

type Props = GridSprinkles &
  HTMLProperties & {
    as?: React.ElementType;
    className?: ClassValue;
  };

const Grid = React.forwardRef<HTMLElement, Props>(({ as = 'div', className, ...props }: Props, ref) => {
  const atomProps: Record<string, unknown> = {
    display: 'grid',
  };
  const nativeProps: Record<string, unknown> = {};

  /* eslint-disable no-restricted-syntax */
  for (const key in props) {
    if (gridSprinkles.properties.has(key as keyof GridSprinkles)) {
      atomProps[key] = props[key as keyof typeof props];
    } else {
      nativeProps[key] = props[key as keyof typeof props];
    }
  }

  const atomicClasses = gridSprinkles(atomProps);

  return React.createElement(as, {
    className: clsx(atomicClasses, className),
    ref,
    ...nativeProps,
  });
});

export type GridProps = Parameters<typeof Grid>[0];

Grid.displayName = 'Grid';

export { Grid };
