import { getFileAsset } from '@sanity/asset-utils';

import { projectId, dataset } from './sanity/constants';

import type { Download } from '@solstice/types';

export const getFile = (source: Download.Props) => {
  return getFileAsset(source.download, {
    projectId,
    dataset,
  });
};
