import { Box } from '@ui/components/box';
import { Flex } from '@ui/components/flex';
import Link from '@ui/components/next-link';
import { Text } from '@ui/components/text';
import { map, filter, flow } from 'lodash/fp';

import { hoverLink } from '../../footer.css';

import { pagesContainer } from './pagesContainer.css';

import type { Page as PageT, Category as CategoryT } from '@solstice/types';

type Props = {
  category: CategoryT.Props;
  pages: PageT.Props[];
};

export const PagesContainer = ({ category, pages }: Props) => {
  return (
    <Flex as="ul" className={pagesContainer}>
      {flow(
        filter((page: PageT.Props) => page.category === category.value),
        map((page: PageT.Props) => (
          <Box as="li" key={page.title}>
            <Link href={page.slug.current}>
              <Text className={hoverLink} color="contrast" style="body2">
                {page.title}
              </Text>
            </Link>
          </Box>
        ))
      )(pages)}
    </Flex>
  );
};
