import type { LogoProps } from './types';

export const Logo = ({ height = '70px', ...props }: LogoProps) => (
  <svg fill="none" height={height} role="img" viewBox="0 0 402 303" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Tas Gas logo</title>
    <path
      d="m261.15,166.03c-3.26.82-6.53,1.22-9.25,1.18-2.72-.04-4.71-.52-6.52-1.18-1.64-.66-3.09-1.49-4.18-2.56-1.27-1.05-2.17-2.34-2.9-3.86-1.63-2.94-1.99-7.92-.91-12.57.54-2.32,1.64-4.35,2.9-6.13,1.27-1.78,2.9-3.32,4.9-4.57,2-1.27,4.36-2.27,7.44-2.87,3.26-.62,6.89-.83,11.42-.43,4.35.38,9.25,1.38,13.97,2.9l-.55-13.35c-2.9-.61-5.8-1.09-9.25-1.49-3.26-.38-6.89-.69-11.06-.75-4.18-.05-8.71.16-13.42,1.24-4.71,1.07-9.43,3.01-13.24,5.71-3.81,2.72-6.71,6.22-8.7,9.72-3.63,6.78-4.53,14.22-3.08,21.55.72,3.56,2.18,6.89,4.17,9.58,1.99,2.66,4.53,4.69,7.26,6.26,2.9,1.58,5.81,2.72,9.8,3.56,3.81.82,8.52,1.32,15.05,1.18,6.53-.13,14.87-.89,23.03-2.23.36-6.06.54-12.1.91-17.43.18-5.31.54-9.92.91-14.54h-25.57l-.54,11.91h7.98l-.55,9.18Z"
      fill="#336eb6"
    />
    <path
      d="m292.7,147.71c3.63-1.25,7.25-2.48,11.25-3.26,2.18-.38,4.35-.6,5.8-.69,1.63-.09,2.72-.04,3.63.09,1.26.31,1.99.47,2.9,1.53.73,1.19,1.09,2.75-1.09,4.22-.73.47-1.81.94-3.63,1.42-1.81.47-4.18.94-5.63,1.2-1.27.25-1.45.29-1.99.44-1.63.33-5.26,1.14-8.34,2.32-1.45.64-3.08,1.38-4.35,2.43-4.36,2.99-5.63,8.38-4.36,12.79.54,1.3,1.09,2.56,2,3.7.91,1.14,2.17,2.19,3.63,3.01,1.45.81,3.08,1.39,4.89,1.78,3.63.74,8.16.62,12.7-.02l2.91-9.27c-1.27.36-2.54.61-3.45.71-2.18.11-3.81.2-5.44-1.27-1.27-1.85-1.27-3.79,2-5.69.91-.49,2.17-1,3.63-1.47,1.45-.47,3.08-.91,4.35-1.31,1.27-.4,2.18-.74,2.91-1.18-.18,3.34-.36,6.65-.55,8.94-.36,4.04-.36,5.22-.54,7.35-.18.89-.18,1.79-.36,2.68h17.23c0-2.47,0-4.93.19-7.47.18-2.54.18-5.17.36-8,.18-2.81.36-5.86.54-8.52.18-2.69.36-4.99.36-6.89s0-3.39-.55-4.81c-.54-1.42-1.27-2.76-2.53-3.85-1.27-1.07-2.91-1.9-4.54-2.48-4.9-1.68-12.69-1.63-18.86-1-4.35.43-8.88,1.22-13.24,2.21l-1.81,10.37Z"
      fill="#336eb6"
    />
    <path
      d="m378.48,136.14c-2.54-.43-5.08-.78-7.62-1.01-4.89-.45-9.25-.69-13.24-.04-1.99.31-3.99.84-5.44,1.49-1.63.67-2.9,1.49-3.81,2.41-4.36,4.26-4.36,10.59,0,15.27.72.93,1.81,1.91,3.26,3.07,1.45,1.18,3.26,2.56,4.53,3.39,1.09.81,1.45,1.09,1.99,1.49,1.45.96,3.08,2.96,2.36,3.96-.55.96-.73,1.01-1.64,1.36-.54.2-1.27.36-2.35.45-2,.16-5.08-.07-7.8-.78-2.54-.67-4.72-1.5-6.9-2.23l1.46,12.33c5.98,1.16,11.24,1.99,16.14,1.87,2.18-.05,4.53-.29,6.71-.8,2.36-.51,4.36-1.31,5.99-2.16,1.63-.84,2.72-1.74,3.45-2.72,2.53-3.21,2.53-4.68,2.53-7.04-.36-3.08-.91-4.7-3.26-7.47-.72-.96-1.81-1.87-3.26-2.99-1.45-1.11-3.45-2.45-4.53-3.25-1.27-.8-1.63-1.05-1.99-1.43-1.46-1.03-3.45-3.35-2-4.44.36-.2.54-.4,1.09-.56.91-.4,3.63-.51,6.16-.42,3.08.13,5.8.75,8.89,1.9l-.73-11.64Z"
      fill="#336eb6"
    />
    <path
      d="m176.82,83.1c-3.81,2.12-7.44,4.32-10.7,6.35-3.27,2.01-5.99,3.84-8.71,5.75-2.54,1.9-5.26,3.86-7.44,5.68-2.17,1.8-3.99,3.43-5.62,4.95-7.62,7.07-12.7,14.38-15.42,21.98-1.27,3.57-1.64,6.98-1.27,10.46-7.44-13.57-8.7-26.48-5.62-39.15,3.27-12.13,10.88-26.88,22.85-40.17,2.54-2.76,5.26-5.55,8.34-8.54,3.08-2.99,6.53-6.18,10.33-9.36,3.63-3.17,7.62-6.35,12.15-9.7,4.53-3.36,9.43-6.91,14.51-10.34-4.72,5.57-9.43,12.71-11.79,18.53-3.63,8.63-4.72,17.72-4.35,26.86.36,5.13,1.27,10.79,2.72,16.7"
      fill="#336eb6"
    />
    <path
      d="m176.46,85.44c2.18,8.07,4.9,15.54,8.71,23.32,1.27,2.74,2.72,5.53,4.89,9.57,2.36,4.04,5.44,9.31,7.44,12.73,1.81,3.43,2.72,5.01,3.63,6.6,1.64,3.39,3.45,6.78,4.9,10.41.91,1.9,1.63,3.9,2.18,5.46,1.09,3.12,1.45,4.92,1.63,7.35.36,2.34.18,5.02,0,7.58-.54,5.55-1.99,9.9-7.26,14.91-2.36,2.18-4.54,3.52-7.07,4.52-6.71,2.5-16.51,3.84-24.67,2.95-2.9-.31-5.98-.91-9.07-1.76,2.54.13,4.9.04,7.44-.2,2.54-.25,5.26-.62,7.44-1.16,4.17-1.05,7.44-2.07,11.25-6.58,2.36-3.12,2.54-5.64,2.54-9.05-.18-4.01-1.63-6.55-3.63-10.37-4.72-8.42-13.06-17.74-17.05-26.99-4.36-9.61-6.89-20.84-4.36-30.67,1.27-5.21,3.99-10.48,8.16-15.31.91-1.11,1.81-2.23,2.9-3.32"
      fill="#79ba43"
    />
    <path
      d="m19.76,122.09l-.36,12.59c3.27-.11,6.71-.2,8.89-.27,3.99-.11,5.07-.11,7.07-.15h2.54c-.18,14.67-1.09,29.52-2.54,44.54h19.04c.18-7.98.54-15.94.72-23.38.36-7.42.91-14.29,1.27-21.16,5.44.09,10.88.2,13.96.27,3.08.07,3.81.11,4.53.14l.54-12.59H19.76Z"
      fill="#79ba43"
    />
    <path
      d="m77.07,148.35c3.63-1.25,7.25-2.47,11.24-3.25,2.18-.4,4.36-.62,5.8-.69,1.63-.09,2.72-.06,3.63.09,1.27.29,2,.45,2.9,1.53.72,1.19,1.09,2.74-1.09,4.22-.72.45-1.81.92-3.63,1.4-1.81.49-4.17.96-5.62,1.22-1.27.25-1.45.29-1.99.43-1.63.31-5.26,1.14-8.34,2.32-1.63.64-3.09,1.38-4.36,2.41-4.35,3.01-5.62,8.4-4.35,12.8.55,1.31,1.09,2.54,1.99,3.7.91,1.14,2.18,2.18,3.63,2.99,1.45.82,3.09,1.42,4.9,1.78,3.63.74,8.16.62,12.7,0l2.9-9.27c-1.27.36-2.54.62-3.44.71-2.18.09-3.81.2-5.44-1.27-1.27-1.87-1.27-3.79,2-5.69.91-.51,2.18-1,3.63-1.47,1.45-.47,3.09-.91,4.35-1.31,1.27-.4,1.99-.76,2.9-1.18-.18,3.34-.36,6.66-.54,8.92-.36,4.04-.36,5.24-.54,7.36-.18.89-.18,1.78-.36,2.67h17.23c0-2.45,0-4.91.18-7.45.18-2.56.18-5.17.36-8,.18-2.83.36-5.86.54-8.54.18-2.67.36-4.99.36-6.89s0-3.38-.54-4.79c-.55-1.42-1.27-2.76-2.54-3.85-1.27-1.09-2.9-1.9-4.53-2.48-4.9-1.69-12.7-1.63-18.86-1.02-4.35.45-8.89,1.23-13.24,2.23l-1.81,10.35Z"
      fill="#79ba43"
    />
    <path
      d="m164.85,136.8c-2.72-.44-5.26-.78-7.8-1.03-4.72-.44-9.07-.69-13.24-.02-1.82.31-3.81.82-5.44,1.49-1.63.67-2.72,1.49-3.81,2.39-4.17,4.26-4.17,10.61,0,15.29.91.91,1.81,1.89,3.27,3.06,1.45,1.18,3.45,2.56,4.53,3.37,1.27.83,1.63,1.11,2.17,1.51,1.27.94,2.9,2.95,2.36,3.95-.55.96-.91,1-1.63,1.36-.54.2-1.27.36-2.36.44-2,.18-5.08-.05-7.98-.76-2.54-.67-4.71-1.52-6.71-2.23l1.27,12.31c5.98,1.18,11.42,1.99,16.14,1.87,2.36-.05,4.71-.27,6.89-.8,2.17-.51,4.35-1.29,5.98-2.14,1.45-.85,2.54-1.76,3.44-2.72,2.54-3.23,2.36-4.68,2.36-7.06-.18-3.07-.91-4.68-3.08-7.47-.91-.97-1.81-1.85-3.26-2.98-1.45-1.12-3.45-2.45-4.72-3.25-1.09-.8-1.45-1.05-1.99-1.43-1.45-1.03-3.26-3.36-1.81-4.44.18-.2.54-.4.91-.56.91-.4,3.63-.53,6.35-.42,2.9.11,5.81.74,8.7,1.9l-.54-11.64Z"
      fill="#79ba43"
    />
    <polygon
      fill="#808184"
      points="198.54 202.95 197.52 222.53 193.57 222.53 184.31 209.27 183.63 222.53 179.47 222.53 180.52 202.95 184.68 202.95 193.75 215.72 194.38 202.95 198.54 202.95"
    />
    <polygon
      fill="#808184"
      points="222.03 202.95 221.86 206.24 213.8 206.24 213.55 210.84 220.63 210.84 220.46 214.13 213.37 214.13 213.1 219.26 223.26 219.26 223.08 222.53 208.76 222.53 209.81 202.95 222.03 202.95"
    />
    <polygon
      fill="#808184"
      points="247.81 202.95 247.63 206.24 241.27 206.24 240.39 222.53 236.23 222.53 237.11 206.24 230.9 206.24 231.08 202.95 247.81 202.95"
    />
    <polygon
      fill="#808184"
      points="282.03 202.95 274.11 222.78 272.42 222.78 268.21 210.67 262.66 222.78 260.95 222.78 255.21 202.95 259.14 202.95 262.28 214.47 267.52 202.95 269.54 202.95 273.53 214.47 277.82 202.95 282.03 202.95"
    />
    <path
      d="m297.74,202.7c2.84,0,5.23.83,7.17,2.49,1.94,1.66,3.02,3.81,3.25,6.43.29,3.3-.53,5.98-2.46,8.05-1.93,2.07-4.55,3.1-7.84,3.1-2.86,0-5.29-.85-7.3-2.56-2.01-1.71-3.13-3.89-3.36-6.53-.27-3.15.59-5.77,2.59-7.86s4.65-3.13,7.96-3.13m-.17,16.47c1.93,0,3.51-.71,4.74-2.12,1.23-1.41,1.75-3.12,1.58-5.14-.14-1.62-.79-2.95-1.94-4.01-1.15-1.06-2.54-1.58-4.17-1.58-1.89,0-3.45.71-4.69,2.12-1.24,1.41-1.78,3.1-1.6,5.06.15,1.67.79,3.04,1.92,4.09,1.13,1.06,2.52,1.58,4.17,1.58"
      fill="#808184"
    />
    <path
      d="m318.52,202.95h6.71c2.09,0,3.77.58,5.02,1.75,1.26,1.16,1.95,2.54,2.09,4.14.11,1.29-.17,2.4-.84,3.32-.67.92-1.7,1.52-3.07,1.79v.06c.82.31,1.57,1.07,2.26,2.3l3.56,6.22h-4.59l-2.22-3.96c-.98-1.76-1.72-2.86-2.22-3.3-.5-.44-1.26-.66-2.28-.66h-.88l-.41,7.92h-4.16l1.02-19.58Zm3.97,3.29l-.27,5.33h2.06c1.26,0,2.22-.27,2.88-.8.66-.54.95-1.22.88-2.03-.07-.77-.41-1.38-1.03-1.82s-1.44-.66-2.47-.66h-2.04Z"
      fill="#808184"
    />
    <polygon
      fill="#808184"
      points="359.42 202.95 351.4 211.8 359.95 222.53 355.14 222.53 346.85 212.15 346.29 222.53 342.13 222.53 343.18 202.95 347.34 202.95 346.89 211.3 354.48 202.95 359.42 202.95"
    />
    <path
      d="m378.58,204.24l-.27,3.45c-.75-.5-1.59-.92-2.54-1.24-.95-.32-1.77-.48-2.46-.48-.78,0-1.42.2-1.91.61-.49.41-.71.92-.65,1.55.03.38.21.75.54,1.13.33.38,1.31,1.03,2.96,1.97,1.65.93,2.78,1.85,3.4,2.75.62.9.97,1.84,1.06,2.81.15,1.73-.32,3.16-1.42,4.3-1.1,1.14-2.66,1.7-4.69,1.7-2.57,0-4.84-.62-6.82-1.86l.33-3.41c.89.6,1.87,1.08,2.94,1.45,1.06.37,1.99.55,2.77.55.85,0,1.52-.2,1.99-.59.48-.4.69-.92.63-1.56-.04-.45-.25-.9-.62-1.36-.37-.46-1.39-1.17-3.05-2.14-1.66-.97-2.77-1.87-3.34-2.69-.57-.82-.89-1.67-.97-2.55-.15-1.68.39-3.09,1.59-4.22,1.21-1.13,2.8-1.69,4.77-1.69s3.88.51,5.73,1.54"
      fill="#808184"
    />
    <path
      d="m44.05,243.37c.89.89,1.33,2.07,1.33,3.55,0,.18-.02.48-.06.92-.22,1.72-.91,3.1-2.07,4.15-1.17,1.05-2.61,1.57-4.32,1.57h-5.6c-.08,0-.12.04-.12.12l-1.1,8.82c-.04.2-.15.3-.33.3h-1.45c-.1,0-.17-.03-.22-.09-.05-.06-.06-.13-.04-.21l2.49-20.16c.04-.2.15-.3.33-.3h7.55c1.52,0,2.72.44,3.61,1.33Zm-1.94,7.17c.82-.85,1.23-1.94,1.23-3.29,0-1.03-.31-1.85-.92-2.46-.61-.61-1.43-.92-2.46-.92h-5.45c-.08,0-.12.04-.12.12l-.95,7.7c0,.08.03.12.09.12h5.48c1.24,0,2.27-.42,3.09-1.27Z"
      fill="#7c7b7b"
    />
    <path
      d="m57.08,247.9c.06-.06.14-.09.24-.09h1.45c.18,0,.27.1.27.3l-1.78,14.39c-.04.2-.15.3-.33.3h-1.48c-.08,0-.14-.03-.19-.09-.05-.06-.06-.13-.04-.21l.21-1.72c.02-.06,0-.09-.03-.1-.04,0-.07.01-.09.07-.47.71-1.07,1.27-1.79,1.67-.72.4-1.54.61-2.47.61-1.4,0-2.53-.46-3.39-1.38s-1.29-2.11-1.29-3.57c0-.51.09-1.43.27-2.75.1-.89.2-1.62.31-2.21.11-.58.25-1.12.43-1.61.43-1.17,1.16-2.11,2.18-2.84,1.02-.73,2.18-1.09,3.48-1.09,1.8,0,3,.74,3.61,2.22.02.06.04.08.07.07.03,0,.05-.04.07-.1l.21-1.66c0-.08.03-.15.09-.21Zm-1.29,9.47c.07-.36.17-1.04.31-2.04.14-1.3.21-2.13.21-2.49,0-.26-.01-.45-.03-.59-.04-.81-.33-1.49-.87-2.03-.54-.54-1.27-.81-2.18-.81s-1.75.27-2.46.8-1.2,1.21-1.48,2.04c-.12.32-.21.66-.28,1.04-.07.38-.16,1.05-.28,2.01-.16,1.16-.24,1.95-.24,2.37,0,.18.02.4.06.68.06.83.39,1.51.98,2.04.59.53,1.34.8,2.25.8s1.69-.27,2.34-.8c.65-.53,1.11-1.21,1.39-2.04.12-.3.21-.62.28-.98Z"
      fill="#7c7b7b"
    />
    <path
      d="m70.87,248.05c.12.08.16.21.12.38l-.5,1.45c-.1.2-.23.26-.38.18-.32-.14-.68-.21-1.1-.21l-.38.03c-1.01.04-1.88.42-2.61,1.14-.73.72-1.17,1.63-1.3,2.74l-1.1,8.73c0,.08-.03.15-.09.21-.06.06-.14.09-.24.09h-1.45c-.18,0-.27-.1-.27-.3l1.78-14.39c.04-.2.15-.3.33-.3h1.45c.1,0,.17.03.22.09.05.06.06.13.04.21l-.24,1.81c0,.06.01.09.04.1.03,0,.06,0,.1-.04.47-.73,1.05-1.3,1.72-1.7.67-.4,1.42-.61,2.25-.61.63,0,1.16.13,1.6.39Z"
      fill="#7c7b7b"
    />
    <path
      d="m80.64,249.47c-.06.06-.13.09-.21.09h-3.26c-.08,0-.12.04-.12.12l-1.1,8.73c-.02.16-.03.37-.03.62,0,.71.19,1.21.56,1.51.37.3.95.44,1.72.44h.71c.18,0,.27.1.27.3l-.15,1.21c-.04.2-.15.3-.33.3-.24.02-.59.03-1.07.03-1.24,0-2.19-.21-2.83-.62-.64-.42-.96-1.17-.96-2.28,0-.18.02-.46.06-.86l1.15-9.39c0-.08-.04-.12-.12-.12h-1.75c-.2,0-.28-.1-.24-.3l.12-1.16c.02-.08.06-.15.12-.21s.13-.09.21-.09h1.78s.04,0,.07-.03.04-.05.04-.09l.41-3.43c.04-.2.15-.3.33-.3h1.42c.08,0,.14.03.19.09.05.06.06.13.04.21l-.41,3.43c0,.08.03.12.09.12h3.26c.2,0,.28.1.24.3l-.12,1.16c-.02.08-.06.15-.12.21Z"
      fill="#7c7b7b"
    />
    <path
      d="m88.87,261.93c-.91-.73-1.47-1.74-1.69-3.02-.06-.41-.09-.89-.09-1.42,0-.59.05-1.33.15-2.22.18-1.5.45-2.7.83-3.61.51-1.26,1.32-2.26,2.41-2.99,1.1-.73,2.34-1.09,3.75-1.09s2.49.37,3.39,1.09c.9.73,1.46,1.72,1.67,2.96.08.38.12.84.12,1.39,0,.43-.06,1.18-.18,2.25-.2,1.58-.47,2.79-.83,3.64-.51,1.28-1.31,2.29-2.4,3.02-1.09.73-2.32,1.1-3.7,1.1s-2.53-.37-3.43-1.1Zm6.11-1.53c.72-.52,1.25-1.23,1.58-2.12.26-.67.46-1.67.62-2.99.12-.93.18-1.7.18-2.31,0-.32-.01-.54-.03-.68-.12-.89-.48-1.59-1.08-2.12-.6-.52-1.37-.79-2.29-.79s-1.75.26-2.46.79c-.71.52-1.24,1.23-1.6,2.12-.24.65-.43,1.65-.59,2.99-.12.89-.18,1.65-.18,2.28,0,.34,0,.57.03.71.1.89.45,1.59,1.05,2.12.6.52,1.38.79,2.32.79s1.72-.26,2.44-.79Z"
      fill="#7c7b7b"
    />
    <path
      d="m107.54,244.63c-.44.48-.74,1.31-.87,2.47l-.06.59s-.02.04,0,.07c.02.03.05.04.09.04h3.23c.2,0,.28.1.24.3l-.15,1.33c-.04.2-.15.3-.33.3h-3.23c-.08,0-.12.04-.12.12l-1.57,12.64c0,.08-.03.15-.09.21-.06.06-.14.09-.24.09h-1.42c-.18,0-.27-.1-.27-.3l1.57-12.64c0-.08-.04-.12-.12-.12h-1.92c-.08,0-.14-.03-.19-.09-.05-.06-.06-.13-.04-.21l.15-1.33c.02-.08.06-.15.12-.21.06-.06.13-.09.21-.09h1.92s.07,0,.1-.03c.03-.02.04-.05.04-.09l.09-.71c.14-1.2.38-2.16.74-2.87.36-.71.88-1.23,1.58-1.55.7-.33,1.64-.49,2.83-.49h.83c.1,0,.17.03.22.09.05.06.06.13.04.21l-.15,1.24c-.04.2-.15.3-.33.3h-.65c-1.07,0-1.82.24-2.27.73Z"
      fill="#7c7b7b"
    />
    <path
      d="m117.28,261.74c-1.14-.86-1.7-2.03-1.7-3.51,0-.18.02-.42.06-.74l.12-.92c.04-.2.15-.3.33-.3h1.39c.18,0,.27.1.27.3l-.12.8c-.02.1-.03.25-.03.44,0,1.01.42,1.82,1.26,2.44.84.62,2.01.93,3.51.93s2.62-.32,3.46-.96c.85-.64,1.34-1.5,1.48-2.56.02-.1.03-.26.03-.47,0-.83-.35-1.53-1.05-2.12-.7-.58-1.93-1.28-3.69-2.09-1.26-.53-2.25-1-2.96-1.41-.71-.4-1.28-.91-1.72-1.53-.43-.61-.65-1.36-.65-2.25,0-.14.02-.38.06-.74.2-1.66.91-2.95,2.15-3.86,1.23-.92,2.83-1.38,4.78-1.38s3.48.44,4.57,1.32c1.1.88,1.64,2.06,1.64,3.54,0,.16-.02.41-.06.77l-.06.65c-.04.2-.15.3-.33.3h-1.45c-.08,0-.14-.03-.19-.09-.05-.06-.06-.13-.04-.21l.06-.53c.02-.12.03-.28.03-.47,0-1.03-.39-1.85-1.17-2.49s-1.89-.95-3.33-.95c-1.34,0-2.41.29-3.21.86s-1.27,1.4-1.41,2.49c-.02.12-.03.28-.03.47,0,.59.16,1.09.49,1.51.33.41.79.78,1.41,1.11.61.33,1.52.74,2.72,1.23,1.22.53,2.21,1.04,2.96,1.51.75.47,1.36,1.04,1.82,1.69.46.65.7,1.43.7,2.34,0,.32-.01.55-.03.71-.2,1.66-.95,2.98-2.25,3.97s-3.01,1.48-5.12,1.48c-1.99,0-3.56-.43-4.69-1.29Z"
      fill="#7c7b7b"
    />
    <path
      d="m134.26,261.93c-.91-.73-1.47-1.74-1.69-3.02-.06-.41-.09-.89-.09-1.42,0-.59.05-1.33.15-2.22.18-1.5.45-2.7.83-3.61.51-1.26,1.32-2.26,2.41-2.99,1.1-.73,2.34-1.09,3.75-1.09s2.49.37,3.39,1.09c.9.73,1.46,1.72,1.67,2.96.08.38.12.84.12,1.39,0,.43-.06,1.18-.18,2.25-.2,1.58-.47,2.79-.83,3.64-.51,1.28-1.31,2.29-2.4,3.02-1.09.73-2.32,1.1-3.7,1.1s-2.53-.37-3.43-1.1Zm6.11-1.53c.72-.52,1.25-1.23,1.58-2.12.26-.67.46-1.67.62-2.99.12-.93.18-1.7.18-2.31,0-.32-.01-.54-.03-.68-.12-.89-.48-1.59-1.08-2.12-.6-.52-1.37-.79-2.29-.79s-1.75.26-2.46.79c-.71.52-1.24,1.23-1.6,2.12-.24.65-.43,1.65-.59,2.99-.12.89-.18,1.65-.18,2.28,0,.34,0,.57.03.71.1.89.45,1.59,1.05,2.12.6.52,1.38.79,2.32.79s1.72-.26,2.44-.79Z"
      fill="#7c7b7b"
    />
    <path
      d="m147.47,262.5l2.49-20.13c.04-.2.15-.3.33-.3h1.45c.1,0,.17.03.22.09.05.06.06.13.04.21l-2.49,20.13c0,.08-.03.15-.09.21-.06.06-.14.09-.24.09h-1.45c-.18,0-.27-.1-.27-.3Z"
      fill="#7c7b7b"
    />
    <path
      d="m154.84,261.93c-.91-.67-1.36-1.52-1.36-2.55,0-.16,0-.29.03-.38l.03-.36c.04-.2.15-.3.33-.3h1.39c.08,0,.14.03.19.09.05.06.06.13.04.21l-.03.24c-.06.41.04.81.3,1.18s.64.68,1.14.9c.5.23,1.08.34,1.73.34,1.05,0,1.92-.26,2.62-.78.7-.52,1.05-1.17,1.05-1.94,0-.43-.14-.79-.43-1.07-.29-.28-.62-.49-1.01-.65-.38-.16-1-.37-1.85-.65-.97-.32-1.76-.62-2.38-.9-.62-.29-1.15-.68-1.57-1.17-.42-.49-.64-1.12-.64-1.87,0-.2,0-.36.03-.47.16-1.26.76-2.27,1.82-3.02,1.06-.75,2.36-1.13,3.92-1.13s2.69.34,3.57,1.02c.88.68,1.32,1.55,1.32,2.62,0,.2-.01.34-.03.44l-.03.09c-.04.2-.15.3-.33.3h-1.33c-.1,0-.17-.03-.22-.09-.05-.06-.06-.13-.04-.21l.03-.09c.08-.67-.17-1.23-.74-1.69-.57-.45-1.38-.68-2.43-.68s-1.87.23-2.53.68c-.66.45-.99,1.07-.99,1.84,0,.59.26,1.05.77,1.36.51.32,1.32.65,2.43,1.01,1.03.32,1.85.61,2.46.87.61.27,1.14.65,1.6,1.15.45.5.68,1.14.68,1.91,0,.22,0,.38-.03.5-.16,1.26-.78,2.29-1.85,3.06-1.08.78-2.41,1.17-4.01,1.17s-2.73-.34-3.64-1.01Z"
      fill="#7c7b7b"
    />
    <path
      d="m175.33,249.47c-.06.06-.13.09-.21.09h-3.26c-.08,0-.12.04-.12.12l-1.1,8.73c-.02.16-.03.37-.03.62,0,.71.19,1.21.56,1.51.37.3.95.44,1.72.44h.71c.18,0,.27.1.27.3l-.15,1.21c-.04.2-.15.3-.33.3-.24.02-.59.03-1.07.03-1.24,0-2.19-.21-2.83-.62-.64-.42-.96-1.17-.96-2.28,0-.18.02-.46.06-.86l1.15-9.39c0-.08-.04-.12-.12-.12h-1.75c-.2,0-.28-.1-.24-.3l.12-1.16c.02-.08.06-.15.12-.21.06-.06.13-.09.21-.09h1.78s.04,0,.07-.03c.03-.02.04-.05.04-.09l.41-3.43c.04-.2.15-.3.33-.3h1.42c.08,0,.14.03.19.09.05.06.06.13.04.21l-.42,3.43c0,.08.03.12.09.12h3.26c.2,0,.28.1.24.3l-.12,1.16c-.02.08-.06.15-.12.21Z"
      fill="#7c7b7b"
    />
    <path
      d="m177.22,262.47l1.78-14.39c.04-.2.15-.3.33-.3h1.45c.1,0,.17.03.22.09.05.06.06.13.04.21l-1.78,14.39c-.04.2-.15.3-.33.3h-1.45c-.18,0-.27-.1-.27-.3Zm2.21-17.78c-.27-.27-.4-.61-.4-1.02,0-.53.18-.98.55-1.35s.79-.55,1.29-.55c.42,0,.75.13,1.02.38.27.26.4.6.4,1.04,0,.53-.18.98-.53,1.35-.36.37-.79.55-1.3.55-.42,0-.75-.13-1.02-.4Z"
      fill="#7c7b7b"
    />
    <path
      d="m185.6,261.92c-.89-.74-1.44-1.74-1.66-3.01-.06-.39-.09-.81-.09-1.24,0-.71.06-1.52.18-2.43.18-1.46.45-2.67.83-3.61.49-1.22,1.28-2.21,2.37-2.95,1.08-.74,2.32-1.11,3.7-1.11s2.57.37,3.49,1.09c.93.73,1.48,1.64,1.66,2.72.04.24.06.53.06.89,0,.18-.1.29-.3.33l-1.45.21h-.06c-.16,0-.24-.09-.24-.27l-.03-.44c-.08-.75-.42-1.39-1.04-1.91-.61-.52-1.39-.79-2.34-.79s-1.78.26-2.49.79c-.71.52-1.21,1.22-1.51,2.09-.28.71-.48,1.71-.62,2.99-.1.77-.15,1.51-.15,2.22,0,.4,0,.66.03.8.08.87.4,1.57.98,2.1.57.53,1.34.8,2.31.8s1.79-.25,2.53-.75c.74-.5,1.24-1.15,1.5-1.94.02-.02.03-.06.03-.12l.03-.12c.06-.2.19-.28.39-.24l1.39.24c.2.04.27.15.21.33-.04.18-.1.38-.18.59-.43,1.15-1.21,2.07-2.32,2.78s-2.38,1.07-3.8,1.07-2.52-.37-3.41-1.11Z"
      fill="#7c7b7b"
    />
    <path
      d="m210.23,255.81c0,.08-.03.15-.09.21s-.14.09-.24.09h-9.27c-.08,0-.12.04-.12.12-.08.87-.12,1.42-.12,1.66,0,1.01.32,1.81.96,2.41.64.6,1.54.9,2.71.9.83,0,1.56-.19,2.21-.56s1.18-.91,1.61-1.6c.16-.18.3-.22.42-.12l1.04.68c.16.1.18.24.06.41-.59.95-1.4,1.69-2.41,2.24-1.02.54-2.16.81-3.42.81-1.36-.02-2.46-.36-3.29-1.01s-1.38-1.56-1.66-2.72c-.12-.51-.18-1.09-.18-1.72,0-.69.06-1.48.18-2.37.1-.85.2-1.53.3-2.04.1-.51.23-.98.38-1.39.47-1.28,1.25-2.31,2.34-3.08s2.33-1.15,3.73-1.15c1.74,0,3.02.45,3.85,1.36.83.91,1.25,2.19,1.27,3.85,0,.75-.09,1.76-.27,3.02Zm-7.51-5.64c-.68.5-1.18,1.18-1.5,2.03-.14.4-.3,1.07-.47,2.01,0,.08.04.12.12.12h7.43c.08,0,.12-.04.12-.12.04-.79.06-1.29.06-1.51,0-.99-.3-1.78-.89-2.38-.59-.6-1.41-.9-2.46-.9-.93,0-1.73.25-2.41.75Z"
      fill="#7c7b7b"
    />
    <path
      d="m232.67,243.9h-10.57c-.06,0-.11.04-.15.12l-.89,7.31c0,.08.04.12.12.12h7.4c.2,0,.28.1.24.3l-.15,1.24c-.04.2-.15.3-.33.3h-7.4c-.08,0-.12.04-.12.12l-.92,7.43c0,.08.03.12.09.12h10.57c.18,0,.27.1.27.3l-.18,1.24c0,.08-.03.15-.09.21s-.14.09-.24.09h-12.43c-.08,0-.14-.03-.19-.09-.05-.06-.06-.13-.04-.21l2.49-20.13c0-.08.03-.15.09-.21.06-.06.13-.09.21-.09h12.44c.1,0,.17.03.22.09.05.06.06.13.04.21l-.15,1.24c-.04.2-.15.3-.33.3Z"
      fill="#7c7b7b"
    />
    <path
      d="m245.02,248.76c.79.77,1.18,1.82,1.18,3.14,0,.16-.02.43-.06.83l-1.18,9.77c-.04.2-.15.3-.33.3h-1.48c-.08,0-.14-.03-.19-.09s-.06-.13-.04-.21l1.15-9.36c.02-.14.03-.33.03-.59,0-.95-.27-1.7-.81-2.26-.54-.56-1.28-.84-2.21-.84-1.07,0-1.96.33-2.69,1.01-.73.67-1.17,1.55-1.3,2.64l-1.15,9.42c0,.08-.03.15-.09.21-.06.06-.14.09-.24.09h-1.45c-.18,0-.27-.1-.27-.3l1.78-14.39c.04-.2.15-.3.33-.3h1.45c.1,0,.17.03.22.09.05.06.06.13.04.21l-.15,1.27s-.01.07.01.09c.03.02.06,0,.1-.03.47-.61,1.06-1.07,1.76-1.38.7-.31,1.48-.46,2.32-.46,1.38,0,2.47.38,3.26,1.15Z"
      fill="#7c7b7b"
    />
    <path
      d="m261.04,255.81c0,.08-.03.15-.09.21s-.14.09-.24.09h-9.27c-.08,0-.12.04-.12.12-.08.87-.12,1.42-.12,1.66,0,1.01.32,1.81.96,2.41.64.6,1.54.9,2.71.9.83,0,1.56-.19,2.21-.56s1.18-.91,1.61-1.6c.16-.18.3-.22.42-.12l1.04.68c.16.1.18.24.06.41-.59.95-1.4,1.69-2.41,2.24-1.02.54-2.16.81-3.42.81-1.36-.02-2.46-.36-3.29-1.01s-1.38-1.56-1.66-2.72c-.12-.51-.18-1.09-.18-1.72,0-.69.06-1.48.18-2.37.1-.85.2-1.53.3-2.04.1-.51.23-.98.38-1.39.47-1.28,1.25-2.31,2.34-3.08s2.33-1.15,3.73-1.15c1.74,0,3.02.45,3.85,1.36.83.91,1.25,2.19,1.27,3.85,0,.75-.09,1.76-.27,3.02Zm-7.51-5.64c-.68.5-1.18,1.18-1.5,2.03-.14.4-.3,1.07-.47,2.01,0,.08.04.12.12.12h7.43c.08,0,.12-.04.12-.12.04-.79.06-1.29.06-1.51,0-.99-.3-1.78-.89-2.38-.59-.6-1.41-.9-2.46-.9-.93,0-1.73.25-2.41.75Z"
      fill="#7c7b7b"
    />
    <path
      d="m273.21,248.05c.12.08.16.21.12.38l-.5,1.45c-.1.2-.23.26-.39.18-.32-.14-.68-.21-1.09-.21l-.39.03c-1.01.04-1.88.42-2.61,1.14s-1.16,1.63-1.3,2.74l-1.09,8.73c0,.08-.03.15-.09.21-.06.06-.14.09-.24.09h-1.45c-.18,0-.27-.1-.27-.3l1.78-14.39c.04-.2.15-.3.33-.3h1.45c.1,0,.17.03.22.09.05.06.06.13.04.21l-.24,1.81c0,.06.01.09.04.1.03,0,.06,0,.1-.04.47-.73,1.05-1.3,1.72-1.7s1.42-.61,2.25-.61c.63,0,1.17.13,1.6.39Z"
      fill="#7c7b7b"
    />
    <path
      d="m284.81,247.9c.06-.06.14-.09.24-.09h1.45c.18,0,.27.1.27.3l-1.75,14.18c-.28,2.21-1.09,3.83-2.46,4.86-1.36,1.03-3.12,1.54-5.27,1.54-.45,0-.79,0-1.01-.03-.2-.02-.29-.13-.27-.33l.24-1.36c.04-.22.15-.31.33-.27l.8.03c1.76,0,3.08-.36,3.97-1.08.89-.72,1.43-1.86,1.63-3.42l.15-1.1s-.01-.06-.04-.07c-.03,0-.06,0-.1.04-1.01,1.18-2.33,1.78-3.97,1.78-1.24,0-2.31-.34-3.2-1.04-.89-.69-1.42-1.67-1.6-2.93-.04-.43-.06-.8-.06-1.09,0-.73.06-1.57.18-2.52.2-1.72.47-2.98.83-3.79.49-1.18,1.23-2.14,2.22-2.86.99-.72,2.11-1.08,3.38-1.08,1.7,0,2.92.58,3.67,1.75.02.04.05.05.09.03.04-.02.06-.05.06-.09l.15-1.16c0-.08.03-.15.09-.21Zm-1.24,9.36c.06-.36.15-1.02.27-1.98.16-1.32.24-2.16.24-2.52,0-.2,0-.36-.03-.47-.04-.83-.34-1.51-.89-2.06s-1.28-.81-2.19-.81-1.71.27-2.4.8c-.69.53-1.2,1.22-1.54,2.07-.26.61-.47,1.6-.65,2.96-.1.73-.15,1.43-.15,2.1,0,.36.02.65.06.89.1.83.44,1.51,1.02,2.06.58.54,1.33.81,2.24.81s1.75-.27,2.41-.8c.66-.53,1.12-1.21,1.38-2.04.1-.32.18-.65.24-1.01Z"
      fill="#7c7b7b"
    />
    <path
      d="m287.65,268.42l.15-1.18c0-.08.03-.15.09-.21.06-.06.14-.09.24-.09h.09c.75-.02,1.35-.13,1.79-.34s.84-.59,1.2-1.14c.36-.55.78-1.41,1.27-2.58.02-.04.03-.07.04-.1s0-.05-.01-.07l-3.05-14.57v-.06c0-.18.09-.27.27-.27h1.51c.16,0,.27.08.33.24l2.25,11.96s.05.06.09.06.07-.02.09-.06l5.15-11.96c.02-.08.07-.14.15-.18.08-.04.15-.06.21-.06h1.51c.22,0,.28.11.18.33l-7.25,15.96c-.61,1.32-1.16,2.3-1.64,2.93-.48.63-1.04,1.07-1.67,1.32-.63.25-1.5.37-2.61.37h-.21c-.14,0-.19-.1-.15-.3Z"
      fill="#7c7b7b"
    />
    <path
      d="m308.35,261.5c-1.09-1.02-1.63-2.36-1.63-4.04,0-.2.02-.51.06-.95l1.01-8.17c.16-1.28.58-2.42,1.27-3.41.69-.99,1.58-1.75,2.66-2.29s2.29-.81,3.61-.81c1.84,0,3.3.5,4.38,1.5s1.63,2.3,1.63,3.92c0,.18-.02.46-.06.86l-.03.38c-.04.2-.15.3-.33.3h-1.48c-.2,0-.28-.1-.24-.3l.03-.35c.02-.16.03-.38.03-.65,0-1.14-.37-2.07-1.11-2.77-.74-.7-1.75-1.05-3.03-1.05-1.4,0-2.59.42-3.57,1.27-.98.85-1.55,1.96-1.73,3.35l-1.01,8.29c-.02.16-.03.38-.03.65,0,1.2.38,2.17,1.15,2.89.77.72,1.81,1.08,3.11,1.08,1.4,0,2.57-.39,3.49-1.18.93-.79,1.47-1.85,1.63-3.17l.33-2.69s.02-.04,0-.07c-.02-.03-.05-.04-.09-.04h-4.23c-.08,0-.14-.03-.19-.09-.05-.06-.06-.13-.04-.21l.15-1.24c.04-.2.15-.3.33-.3h6.1c.08,0,.14.03.19.09.05.06.06.13.04.21l-.47,3.82c-.26,2.11-1.07,3.76-2.43,4.94-1.36,1.18-3.07,1.78-5.12,1.78-1.84,0-3.3-.51-4.38-1.53Z"
      fill="#7c7b7b"
    />
    <path
      d="m332.92,248.05c.12.08.16.21.12.38l-.5,1.45c-.1.2-.23.26-.39.18-.32-.14-.68-.21-1.09-.21l-.39.03c-1.01.04-1.88.42-2.61,1.14s-1.16,1.63-1.3,2.74l-1.09,8.73c0,.08-.03.15-.09.21-.06.06-.14.09-.24.09h-1.45c-.18,0-.27-.1-.27-.3l1.78-14.39c.04-.2.15-.3.33-.3h1.45c.1,0,.17.03.22.09.05.06.06.13.04.21l-.24,1.81c0,.06.01.09.04.1.03,0,.06,0,.1-.04.47-.73,1.05-1.3,1.72-1.7s1.42-.61,2.25-.61c.63,0,1.17.13,1.6.39Z"
      fill="#7c7b7b"
    />
    <path
      d="m335.68,261.93c-.91-.73-1.47-1.74-1.69-3.02-.06-.41-.09-.89-.09-1.42,0-.59.05-1.33.15-2.22.18-1.5.45-2.7.83-3.61.51-1.26,1.32-2.26,2.41-2.99,1.1-.73,2.34-1.09,3.75-1.09s2.49.37,3.39,1.09c.9.73,1.46,1.72,1.67,2.96.08.38.12.84.12,1.39,0,.43-.06,1.18-.18,2.25-.2,1.58-.47,2.79-.83,3.64-.51,1.28-1.31,2.29-2.4,3.02-1.08.73-2.32,1.1-3.7,1.1s-2.53-.37-3.43-1.1Zm6.11-1.53c.72-.52,1.25-1.23,1.58-2.12.26-.67.46-1.67.62-2.99.12-.93.18-1.7.18-2.31,0-.32,0-.54-.03-.68-.12-.89-.48-1.59-1.08-2.12-.6-.52-1.37-.79-2.29-.79s-1.75.26-2.46.79c-.71.52-1.24,1.23-1.6,2.12-.24.65-.43,1.65-.59,2.99-.12.89-.18,1.65-.18,2.28,0,.34,0,.57.03.71.1.89.45,1.59,1.05,2.12.6.52,1.38.79,2.32.79s1.72-.26,2.44-.79Z"
      fill="#7c7b7b"
    />
    <path
      d="m359.87,247.81h1.45c.1,0,.17.03.22.09.05.06.06.13.04.21l-1.78,14.39c-.04.2-.15.3-.33.3h-1.45c-.18,0-.27-.1-.27-.3l.18-1.27s-.02-.07-.06-.09c-.04-.02-.07,0-.09.03-.95,1.22-2.3,1.83-4.06,1.83-1.3,0-2.38-.37-3.23-1.1-.85-.73-1.27-1.75-1.27-3.05,0-.14.02-.38.06-.74l1.21-10.01c.04-.2.15-.3.33-.3h1.48c.2,0,.28.1.24.3l-1.15,9.39c-.02.14-.03.35-.03.62,0,.95.26,1.69.78,2.24.52.54,1.26.81,2.21.81,1.08,0,1.99-.34,2.72-1.01.73-.67,1.16-1.56,1.3-2.67l1.15-9.39c.04-.2.15-.3.33-.3Z"
      fill="#7c7b7b"
    />
    <path
      d="m376.8,253.08c0,.55-.05,1.29-.15,2.22-.18,1.56-.45,2.78-.83,3.67-.49,1.26-1.24,2.25-2.24,2.98s-2.19,1.08-3.57,1.08c-.77,0-1.45-.15-2.03-.46-.58-.31-1.05-.75-1.41-1.32-.02-.04-.05-.05-.09-.04-.04,0-.06.04-.06.07l-.86,6.96c0,.08-.03.15-.09.21s-.14.09-.24.09h-1.45c-.18,0-.27-.1-.27-.3l2.49-20.13c.04-.2.15-.3.33-.3h1.45c.18,0,.27.1.27.3l-.15,1.24s-.01.06.02.07c.03.01.06,0,.1-.04.49-.57,1.08-1.02,1.75-1.33s1.38-.47,2.13-.47c1.34,0,2.42.35,3.24,1.07s1.33,1.68,1.53,2.9c.08.42.12.93.12,1.54Zm-2.65,4.47c.17-.55.31-1.3.43-2.25.1-.69.15-1.36.15-2.01,0-.53-.07-1.06-.21-1.57-.16-.71-.5-1.27-1.04-1.69-.53-.42-1.19-.62-1.98-.62s-1.45.21-2.03.62c-.58.41-1.03.97-1.35,1.66-.22.41-.4.88-.53,1.41-.14.52-.27,1.26-.38,2.21-.1.71-.15,1.4-.15,2.07,0,.55.07,1.09.21,1.6.16.67.47,1.21.95,1.61.47.41,1.08.61,1.81.61.77,0,1.46-.2,2.07-.59.61-.39,1.08-.92,1.39-1.57.28-.43.5-.93.67-1.48Z"
      fill="#7c7b7b"
    />
  </svg>
);
