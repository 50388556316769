import { createClient } from 'next-sanity';

import { dataset, projectId } from './constants';

export const client = createClient({
  projectId,
  dataset,
  useCdn: process.env.VERCEL_ENV === 'production' ? false : true, // using ISR on production only
  apiVersion: process.env.NEXT_PUBLIC_SANITY_API_VERSION ?? '2023-03-25',
});
