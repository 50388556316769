'use client';

import NextLink from '@ui/components/next-link';
import { isFunction, isObject } from 'lodash';

import { Box } from '../box';

import type { LinkProps as NextLinkProps } from 'next/link';
import type { FC, MouseEvent, MouseEventHandler, ReactElement, PropsWithChildren, CSSProperties } from 'react';

type LinkAdditionalProps = {
  className?: string;
  role?: string;
  title?: string;
  target?: string;
  analytics?: { [key: string]: string | null };
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  href?: string;
  style?: CSSProperties;
};
type LinkProps = PropsWithChildren<NextLinkProps> & LinkAdditionalProps;

const Link: FC<LinkProps> = ({
  children,
  href: rawHref,
  onClick = undefined,
  passHref = true,
  prefetch = false,
  replace = undefined,
  scroll = undefined,
  shallow = undefined,
  style = undefined,
  className = undefined,
  ...props
}): ReactElement => {
  const isRelativeLink = String(rawHref).startsWith('/') || String(rawHref).startsWith('#') || isObject(rawHref);

  const handleOnClick: MouseEventHandler<HTMLAnchorElement> = (event: MouseEvent<HTMLAnchorElement>) => {
    if (isFunction(onClick)) onClick(event);
  };

  const onAuxClick: MouseEventHandler<HTMLAnchorElement> = (event: MouseEvent<HTMLAnchorElement>) => {
    // Check if middle click
    if (event?.button === 1) handleOnClick(event);
  };

  const clickEvents = {
    onClick: handleOnClick,
    onAuxClick,
  };

  if (!rawHref)
    return (
      <Box className={className} style={style}>
        {children}
      </Box>
    );

  // If a href does not start with `http` or is an object, handle it with NextLink
  if (isRelativeLink) {
    return (
      <Box className={className} style={style}>
        <NextLink
          href={rawHref}
          passHref={passHref}
          prefetch={prefetch}
          replace={replace}
          scroll={scroll}
          shallow={shallow}
          {...clickEvents}
          {...props}
        >
          {children}
        </NextLink>
      </Box>
    );
  }

  return (
    // eslint-disable-next-line react/forbid-elements, react/jsx-no-target-blank
    <a
      className={className}
      href={String(rawHref)}
      rel={!isRelativeLink ? 'noreferrer' : undefined}
      style={style}
      target={!isRelativeLink ? '_blank' : undefined}
      {...clickEvents}
      {...props}
    >
      {children}
    </a>
  );
};

export { Link };
