import { colors } from '@style-system/theme.css';

export const Heart = ({ fill }: { fill?: string }) => (
  <svg fill="none" height="64" viewBox="0 0 64 64" width="64" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M38.5 58.5C38.5 60.9853 35.8137 63 32.5 63C29.1863 63 26.5 60.9853 26.5 58.5C26.5 56.0147 29.1863 54 32.5 54C35.8137 54 38.5 56.0147 38.5 58.5Z"
      fill={fill ? fill : colors.secondary600}
    />
    <path
      d="M44.5 1C35.7 1 32.8333 7 32.5 10C31.6667 7 27.7 1 18.5 1C7 1 5 12.5 5 16C5 19.5 8.13344 25.8167 13.5 28.5C20.5 32 26.8333 41.5 30 48C30.1667 48.6667 30.9 50 32.5 50C34.1 50 34.8333 48.6667 35 48C37.5 39.5 44 31 50.5 28.5C57 26 59.5 21.5 59.5 16C59.5 10.5 55.5 1 44.5 1Z"
      fill={fill ? fill : colors.secondary600}
    />
  </svg>
);
