import type { SVGProps } from 'react';

export const CircleArrow = ({ className }: SVGProps<SVGSVGElement>) => (
  <svg className={className} fill="none" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
      fill="#9A65FE"
      fillRule="evenodd"
    />
    <mask height="24" id="mask0_1278_874" maskUnits="userSpaceOnUse" width="24" x="12" y="12">
      <rect fill="#D9D9D9" height="24" width="24" x="12" y="12" />
    </mask>
    <g mask="url(#mask0_1278_874)">
      <path
        d="M28.15 25H17C16.7167 25 16.4792 24.9042 16.2875 24.7125C16.0958 24.5208 16 24.2833 16 24C16 23.7167 16.0958 23.4792 16.2875 23.2875C16.4792 23.0958 16.7167 23 17 23H28.15L25.3 20.15C25.1 19.95 25.0042 19.7167 25.0125 19.45C25.0208 19.1833 25.1167 18.95 25.3 18.75C25.5 18.55 25.7375 18.4458 26.0125 18.4375C26.2875 18.4292 26.525 18.525 26.725 18.725L31.3 23.3C31.4 23.4 31.4708 23.5083 31.5125 23.625C31.5542 23.7417 31.575 23.8667 31.575 24C31.575 24.1333 31.5542 24.2583 31.5125 24.375C31.4708 24.4917 31.4 24.6 31.3 24.7L26.725 29.275C26.525 29.475 26.2875 29.5708 26.0125 29.5625C25.7375 29.5542 25.5 29.45 25.3 29.25C25.1167 29.05 25.0208 28.8167 25.0125 28.55C25.0042 28.2833 25.1 28.05 25.3 27.85L28.15 25Z"
        fill="#200158"
      />
    </g>
  </svg>
);
