import { Text } from '../../text';

import * as styles from './fieldLabel.css';

import type { FieldLabel as FieldLabelT } from '@solstice/types';

export const FieldLabel = ({ fieldId, label, optional }: FieldLabelT.Props) => (
  <label className={styles.container} htmlFor={fieldId}>
    <Text color={'contrast'} style={'body2'} weight={'bold'}>
      {label}
    </Text>
    <Text color={'contrast'} style={'body3'}>
      {optional ? 'Optional' : null}
    </Text>
  </label>
);
