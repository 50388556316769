import { isVictoria } from '../sites';

import type { PipelineLocator } from 'packages/types/src';

const GMAP_API_KEY = process.env.STORYBOOK_GMAPS_API_KEY ?? process.env.NEXT_PUBLIC_GMAPS_API_KEY ?? '';

const SCRIPT_LIBRARIES = ['places', 'geometry', 'marker'];

const RED_LINE_INDICATOR_WORDING =
  'A red line indicates the presence of the gas pipeline but should be used as a guide only. Actual location of pipework may vary.';

const TAS_CONTENT_WORDING = {
  found: {
    contentBold: 'It looks like the natural gas pipeline is right in front of your house!',
    content: "If you'd like to connect, simply apply",
    contentLink: {
      text: 'Online',
      // TODO: when the site is built, replace it with real link
      url: '/apply-online',
    },
    contentItalic: RED_LINE_INDICATOR_WORDING,
  },
  unFound: {
    content: `Your property doesn't appear to be in front of the gas pipeline. If you think this isn't right, please give us a call on 1800 750 750 for assistance.`,
    contentItalic: RED_LINE_INDICATOR_WORDING,
  },
};

const VIC_CONTENT_WORDING = {
  found: {
    heading: 'Success!',
    contentBold: TAS_CONTENT_WORDING.found.contentBold,
    content: 'If you have an existing connection, simply',
    contentLink: {
      text: 'set up a new account',
      // TODO: when the site is built, replace it with real link
      url: '/set-up-a-new-account',
    },
    contentItalic: TAS_CONTENT_WORDING.found.contentItalic,
  },
  unFound: {
    content: TAS_CONTENT_WORDING.unFound.content,
    contentItalic: TAS_CONTENT_WORDING.unFound.contentItalic,
  },
};

const PIPELINE_WORDING: PipelineLocator.PipelineWording = {
  found: {
    icon: {
      name: 'check_circle',
      color: 'primary600',
    },
    heading: 'Congratulations!',
    ...TAS_CONTENT_WORDING.found,
    ...(isVictoria && VIC_CONTENT_WORDING.found),
  },
  unFound: {
    icon: {
      name: 'warning',
      color: 'secondary600',
    },
    heading: 'Sorry!',
    ...TAS_CONTENT_WORDING.unFound,
    ...(isVictoria && VIC_CONTENT_WORDING.unFound),
  },
};

const STANDARD_MAP_ID = process.env.STORYBOOK_STANDARD_GMAP_ID ?? process.env.NEXT_PUBLIC_STANDARD_GMAP_ID ?? '';
// The color theme is not applied here because the map can not be rendered by vanilla-extract
// So using hex is the only way to set the color
const PIPELINE_THEME = {
  pipelineStrokeColor: '#ff5647', // Solstice secondary600.
  markerFillColor: '#9646ff', // Solstice primary600.
  mapId: STANDARD_MAP_ID,
};

const TAS_INITIAL_POSITION = {
  center: { lat: -42.182737, lng: 146.670465 },
  zoom: 7,
  searchBounds: {
    north: -39.573353,
    south: -43.740952,
    west: 143.56139,
    east: 148.479,
  },
};

const VIC_INITIAL_POSITION = {
  center: { lat: -36.466545, lng: 144.953582 },
  zoom: 7,
  searchBounds: {
    north: -34.0,
    south: -39.0,
    west: 140.96,
    east: 150.0,
  },
};

const PROXIMITY_THRESHOLD_FALLBACK = 60; // meters
const POINT_PERCENTAGE_PER_LINE = 0.8;

const CMS_PIPELINE_FETCH_TIMEOUT_THRESHOLD = 10000; // milliseconds

export {
  PIPELINE_WORDING,
  PIPELINE_THEME,
  TAS_INITIAL_POSITION,
  VIC_INITIAL_POSITION,
  PROXIMITY_THRESHOLD_FALLBACK,
  POINT_PERCENTAGE_PER_LINE,
  CMS_PIPELINE_FETCH_TIMEOUT_THRESHOLD,
  GMAP_API_KEY,
  SCRIPT_LIBRARIES,
};
