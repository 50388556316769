import type { PipelineLocator } from 'packages/types/src';

type Line = PipelineLocator.Line;
type Lines = PipelineLocator.Lines;
type LatLng = PipelineLocator.LatLng;
type GeoJsonData = PipelineLocator.GeoJsonData;

const selectivePointsInLine = (percentage: number, line?: Line) => {
  if (!line) return [];
  if (percentage <= 0) return [];
  if (percentage >= 1) return line;

  const pointsNum = Math.round(line.length * percentage);

  const interval = (line.length - 1) / (pointsNum - 1);
  const result = [];

  for (let i = 0; i < pointsNum; i++) {
    result.push(line[Math.round(i * interval)]);
  }

  return result;
};

const refineData = (geoData: GeoJsonData, refinePercentage: number) => {
  // Retrieve all lines, and they are grouped by features
  const lines = geoData.features.map((feature) => feature.geometry.coordinates as Line | undefined);
  // The refine is just retrieve a certain percentage of points in each line with the same interval to speed up the proximity calculation
  const shortenedLineCoords = lines.map((line) => selectivePointsInLine(refinePercentage, line));
  return shortenedLineCoords;
};

const checkProximity = (position: google.maps.LatLng, allLines: Lines, proximityThreshold: number): boolean => {
  let result = false;
  allLines.forEach((lines) => {
    for (let i = 0; i < lines.length - 1; i++) {
      if (i + 1 >= lines.length) break;
      // The original data is [lng, lat], but the google map LatLng is [lat, lng]
      const start = new google.maps.LatLng(lines[i][1], lines[i][0]);
      const end = new google.maps.LatLng(lines[i + 1][1], lines[i + 1][0]);
      // Form a line from start to end and get the middle point
      const interpolatedPoint = google.maps.geometry.spherical.interpolate(start, end, 0.5); // middle point of the line
      // Calculate the distance between the middle point and the position for proximity calculation
      const dist = google.maps.geometry.spherical.computeDistanceBetween(interpolatedPoint, position);
      if (dist < proximityThreshold) {
        result = true;
        return;
      }
    }
    if (result) return;
  });

  return result;
};

const fetchGeoDataFromURLWithTimeout = async (url: string, timeout: number) => {
  const fetchPromise = fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .catch((error) => {
      console.error(error);
      return 'error';
    });

  const timeoutPromise = new Promise((resolve) => setTimeout(() => resolve('timeout'), timeout));

  return await Promise.race([fetchPromise, timeoutPromise]);
};

export { checkProximity, refineData, fetchGeoDataFromURLWithTimeout };
export type { LatLng, Lines, GeoJsonData };
