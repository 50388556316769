import { getImage } from '@solstice/lib';
import { colors, dawnTheme, duskTheme } from '@style-system/theme.css';
import { Breadcrumbs } from '@ui/components/breadcrumbs';
import { HighlightText } from '@ui/components/highlight-text';
import clsx from 'clsx';
import Image from 'next/image';

import { Box } from '../../box';
import { Flex } from '../../flex';
import { Grid } from '../../grid';
import { Text } from '../../text';

import * as styles from './header.css';

import type { Header } from '@solstice/types';
import type { ReactElement } from 'react';

interface AddressLookupComponent {
  AddressLookupComponent?: React.ComponentType;
}

export const HeaderBlock = ({
  title,
  body,
  address,
  image,
  variant,
  theme = 'dusk',
  category,
  pageName,
  AddressLookupComponent,
}: Header.Props & AddressLookupComponent) => {
  const alignment = variant === 'centered' ? 'center' : 'left';
  const highlightTextColor = theme === 'dusk' ? colors.primary900 : colors.secondary600;

  const BreadcrumbsComponent = ({ className }: { className: string }): ReactElement | null => {
    if (category && pageName) {
      return (
        <Box className={className}>
          <Breadcrumbs paths={[{ title: category }, { title: pageName }]} theme={theme} />
        </Box>
      );
    }

    return null;
  };

  return (
    <Flex
      className={clsx(
        theme === 'dawn' && dawnTheme,
        theme === 'dusk' && duskTheme,
        styles.header({ theme }),
        image && styles.mobileWithImage
      )}
      id="address-lookup"
    >
      <Grid className={clsx([styles.wrapper])}>
        <Flex className={styles.contentWrapper({ alignment })}>
          <BreadcrumbsComponent className={styles.breadCrumbsDesktop} />
          <Flex className={styles.content}>
            {title && (
              <HighlightText
                as="h1"
                className={styles.heading}
                color="contrast"
                highlightColor={highlightTextColor}
                style="h2"
                value={title}
              />
            )}
            {body && (
              <Text color="contrast" style="body2">
                {body}
              </Text>
            )}
            {address && AddressLookupComponent && <AddressLookupComponent />}
          </Flex>
        </Flex>
      </Grid>
      {alignment === 'left' && (
        <Box className={styles.maskedImage}>
          <Box className={styles.image} position="relative">
            {image && variant !== 'swoosh' && (
              <Image alt={image?.alt} className={styles.imageContent} fill src={getImage(image).url?.()} />
            )}
          </Box>
        </Box>
      )}
      <BreadcrumbsComponent className={styles.breadCrumbsMobile} />
    </Flex>
  );
};

HeaderBlock.displayName = 'HeaderBlock';
