import { categories } from '@solstice/constants';
import { Box } from '@ui/components/box';
import { Flex } from '@ui/components/flex';
import { Grid } from '@ui/components/grid';
import { map } from 'lodash/fp';

import { CategoryTitle } from '../categoryTitle';
import { PagesContainer } from '../pagesContainer';

import { displayController, gridContainer } from './desktopMenu.css';

import type { SiteName } from '@solstice/constants';
import type { Page as PageT } from '@solstice/types';

type Props = {
  pages: PageT.Props[];
};

export const DesktopMenu = ({ pages }: Props) => {
  return (
    <Box className={displayController}>
      <Grid className={gridContainer}>
        {map(
          (category) => {
            return (
              <Flex direction="column" key={category.value}>
                {!category.singleton && <CategoryTitle title={category.title} />}
                <PagesContainer category={category} pages={pages} />
              </Flex>
            );
          },
          categories[(process.env.NEXT_PUBLIC_SITE ?? 'Solstice') as SiteName]
        )}
      </Grid>
    </Box>
  );
};
