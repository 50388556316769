export const breakpoints = {
  xs: [0, 575],
  s: [576, 767],
  m: [768, 991],
  l: [992, 1199],
  xl: [1200, Infinity],
  xxl: [1601, Infinity],

  // groups
  mobile: [0, 767],
  tablet: [768, 991],
  desktop: [992, Infinity],
} as const;

export const maxWidth = '1120px';
