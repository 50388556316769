export const fieldTypes = {
  radio: 'radio',
  text: 'text',
  date: 'date',
  phone: 'phone',
  number: 'number',
  email: 'email',
  checkbox: 'checkbox',
  dropdown: 'dropdown',
  dropdownItems: 'dropdownItems',
  file: 'file',
} as const;

export const SEND_PROPERTIES = {
  SEND_FROM: '101',
  SEND_TO: '102',
  SEND_TIME: '103',
  SEND_SUBJECT: '104',
};
