import imageUrlBuilder from '@sanity/image-url';

import { client } from './sanity/sanity.client';

import type { SanityImageSource } from '@sanity/image-url/lib/types/types';

const builder = imageUrlBuilder(client);

export const getImage = (source: SanityImageSource) => {
  return builder.image(source);
};
export const getFileData = async (source: string) => {
  const imageData = await client.getDocument(source);
  return imageData;
};
