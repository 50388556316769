import { getImage } from '@solstice/lib';
import clsx from 'clsx';
import Image from 'next/image';

import { dawnTheme, duskTheme } from '../../../styles/theme.css';
import { Box } from '../../box';
import { Button } from '../../button';
import { Card } from '../../card';
import { Container } from '../../container';
import { Flex } from '../../flex';
import { Grid } from '../../grid';
import { Heading } from '../../heading';
import { HighlightText } from '../../highlight-text';
import { TeamMemberComponent } from '../../teamMember';
import { Text } from '../../text';

import * as styles from './gridBlock.css';

import type { Grid as GridT } from '@solstice/types';

export const GridBlock = ({ title, body, image, cta, content, theme, home }: GridT.Props) => (
  <Box
    background={!home ? 'background' : undefined}
    className={clsx(theme === 'dawn' && dawnTheme, theme === 'dusk' && duskTheme)}
  >
    <Container asChild>
      <Flex direction="column" gap="space-e" paddingY="space-j">
        {(title || image || body || cta) && (
          <Grid align="center" columns={12}>
            <Flex align="center" className={styles.heading} direction="column" gap="space-c">
              {title && (
                <Heading as="h2" color="contrast" position="relative" style="h3" textAlign="center">
                  <HighlightText color="contrast" style="h3" value={title} />
                  {image && (
                    <Box className={styles.image} position="absolute">
                      <Image alt={image.alt} fill src={getImage(image).url()} style={{ objectFit: 'contain' }} />
                    </Box>
                  )}
                </Heading>
              )}
              {body && (
                <Text color="contrast" textAlign="center">
                  {body}
                </Text>
              )}
              {cta && <Button href={`/${cta.link}`} label={cta.label} type="primary" />}
            </Flex>
          </Grid>
        )}
        {content && (
          <Grid columnGap="space-h" columns={{ mobile: 1, tablet: 4, desktop: 6 }} rowGap="space-e">
            {content.map((item, index) => (
              <Box className={styles.item} key={index}>
                {item._type === 'card' ? (
                  <Card key={item.title} {...item} />
                ) : (
                  <TeamMemberComponent key={item.name} {...item} />
                )}
              </Box>
            ))}
          </Grid>
        )}
      </Flex>
    </Container>
  </Box>
);
