import * as Accordion from '@radix-ui/react-accordion';
import { categories } from '@solstice/constants';
import { Box } from '@ui/components/box';
import { Flex } from '@ui/components/flex';
import { Icon } from '@ui/components/icon';
import { map } from 'lodash/fp';

import { hoverLink } from '../../footer.css';
import { CategoryTitle } from '../categoryTitle';
import { PagesContainer } from '../pagesContainer';

import { animation, displayController, icon, trigger } from './mobileMenu.css';

import type { SiteName } from '@solstice/constants';
import type { Page as PageT } from '@solstice/types';

type Props = {
  pages: PageT.Props[];
};

export const MobileMenu = ({ pages }: Props) => {
  return (
    <Accordion.Root asChild type="multiple">
      <Box className={displayController}>
        <Flex direction="column" gap="space-f">
          {map(
            (category) => {
              return (
                <Accordion.Item key={category.value} value={category.value}>
                  <Accordion.Trigger asChild className={trigger}>
                    <Flex alignItems="center" className={hoverLink} justifyContent="space-between">
                      <CategoryTitle title={category.title} />
                      <Icon className={icon} name="expand_more" />
                    </Flex>
                  </Accordion.Trigger>
                  <Accordion.Content className={animation}>
                    <PagesContainer category={category} pages={pages} />
                  </Accordion.Content>
                </Accordion.Item>
              );
            },
            categories[(process.env.NEXT_PUBLIC_SITE ?? 'Solstice') as SiteName]
          )}
        </Flex>
      </Box>
    </Accordion.Root>
  );
};
