import Image from 'next/image';

import * as styles from './flourish.css';

type Flourish = {
  src: string;
  alt: string;
  width: number;
  height: number;
};

type Flourishes = {
  [key: string]: Flourish;
};

export const flourishes: Flourishes = {
  kettle: { src: 'kettle.png', alt: 'Kettle flourish', width: 105, height: 105 },
  bulb: { src: 'light-bulb.png', alt: 'Lightbulb flourish', width: 100, height: 100 },
  windmill: { src: 'windmill.png', alt: 'Windmill flourish', width: 100, height: 100 },
  hat: { src: 'hard-hat.png', alt: 'Hardhat flourish', width: 100, height: 100 },
  oven: { src: 'oven.png', alt: 'Oven flourish', width: 100, height: 100 },
};

type FlourishProps = {
  name?: string;
  alignment?: string;
  height?: number;
  width?: number;
};

export const Flourish = ({ name, alignment, height, width }: FlourishProps) => {
  const imageVariant = alignment === 'center' ? 'center' : 'default';
  const image = name && name in flourishes && flourishes[name];

  return image ? (
    <Image
      alt={image.alt}
      className={styles.imageRecipe({ imageVariant })}
      height={height || image.height}
      src={`/${image.src}`}
      width={width || image.width}
    />
  ) : null;
};
